import { ref, get } from "firebase/database";
import { rtDb } from "./initRealtimeDB";

export async function readPath(path: string) {
  try {
    const a = await get(ref(rtDb, path));
    return a.val();
  } catch (error: any) {
    if (error.message === "Permission denied") {
      console.error(`Error on reading from "${path}"`);
      throw Error("PERMISSION_DENIED: Read from realtime database");
    } else {
      console.error("UNKNOWN_ERROR: Read to realtime database");
      console.error(error);
      throw error;
    }
  }
}

// LoginPage.tsx of [snippethub-web], at 210926

import { Button, Divider, InputLabel, Link, Typography } from "@mui/material";
import { IconFollowingCursor } from "component/interactive-comps/IconLookingAtCursor";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory /* useLocation */ } from "react-router-dom";
import { SimplePageWrap } from "UI/SimplePageWrap";
import { FormInfo } from "UI/types";
import { ValidatingField } from "UI/ValidatingField";
import { login } from "cloud/services";
import {
  FireAuthErrorCode,
  FireAuthErrorMsg,
} from "cloud/firebase/fire-constants";
import { showTopSnackbar } from "redux/snackbar";
import { useDispatch } from "react-redux";

export function LoginPage() {
  let history = useHistory();
  const dispatch = useDispatch();
  const showTopSnackbarError = useCallback(
    (message: string) => {
      return dispatch(showTopSnackbar(message, "error"));
    },
    [dispatch]
  );

  const defaultFormInfo = {
    value: "",
    isValid: true,
  };
  const [username, setUsername] = useState<FormInfo>({ ...defaultFormInfo });
  const [password, setPassword] = useState<FormInfo>({ ...defaultFormInfo });
  const usernameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [isDisabledCreateBtn, setIsDisabledLoginBtn] = useState<boolean>(false);

  // disable create button
  useEffect(() => {
    if (![username, password].map((fi) => fi.isValid).every(Boolean)) {
      setIsDisabledLoginBtn(true);
    } else {
      setIsDisabledLoginBtn(false);
    }
  }, [username, password]);

  const localFormCheck = useCallback(
    (username, password): boolean => {
      const checkList: [FormInfo, React.RefObject<HTMLInputElement>, string][] =
        [
          [username, usernameRef, "username"],
          [password, passwordRef, "password"],
        ];
      for (const [info, ref, name] of checkList) {
        if (info.value === "") {
          ref.current?.focus();
          showTopSnackbarError(`Please fill out the field: ${name}`);
          return false;
        }
      }
      return true;
    },
    [showTopSnackbarError]
  );
  const ServerFormCheck = useCallback(
    async (username, password): Promise<boolean> => {
      if (!username.value || !password.value) {
        //asserted in LOCAL CHECK for loop, repeat for TS
        return false;
      }

      try {
        // const loginResponse =
        await login({
          username: username.value,
          password: password.value,
        });
      } catch (err: any) {
        console.log("err : ", err);

        switch (err.code) {
          case FireAuthErrorCode.X_INVALID_USERNAME:
            usernameRef.current?.focus();
            showTopSnackbarError(`Username "${username.value}" doesn't exist`);
            return false;
          case FireAuthErrorCode.TOO_MANY_ATTEMPTS_TRY_LATER:
            usernameRef.current?.focus();
            showTopSnackbarError(
              `${FireAuthErrorMsg.TOO_MANY_ATTEMPTS_TRY_LATER}\nChange password is not available right now.`
            );
            return false;
          default:
            console.error({ err });
            return false;
        }
      }
      history.push("/");
      return true;
    },
    [history, showTopSnackbarError]
  );
  const handleFormSubmit = useCallback(
    async (event, username, password) => {
      event.preventDefault();
      if (!localFormCheck(username, password)) {
        return false;
      }
      if (!(await ServerFormCheck(username, password))) {
        return false;
      }
    },
    [localFormCheck, ServerFormCheck]
  );

  return (
    <SimplePageWrap>
      <form
        onSubmit={(event) => {
          handleFormSubmit(event, username, password);
        }}
      >
        <div style={{ alignSelf: "center" }}>
          <IconFollowingCursor size={200} />
        </div>
        <Typography variant="h5">Sign up</Typography>
        <Divider style={{ margin: "20px 0" }} variant="fullWidth" />
        <InputLabel>Username</InputLabel>
        <ValidatingField
          infoStateHook={[username, setUsername]}
          lengthControl={[3, Infinity]}
          validators={[
            [
              "Name may not contain non-url-safe chars",
              (s) => /^[.a-zA-Z0-9_-]*$/.test(s),
            ],
            ['Name may not start with "."', (s: string) => !s.startsWith(".")],
          ]}
          autocomplete="username"
          inputRef={usernameRef}
          variant="outlined"
          size="small"
        />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <InputLabel>Password</InputLabel>
          <Link href="/recover">Forgot? Reset it with Email</Link>
        </div>
        <ValidatingField
          infoStateHook={[password, setPassword]}
          type="password"
          autocomplete="password"
          inputRef={passwordRef}
          lengthControl={[8, Infinity]}
          variant="outlined"
          size="small"
        />
        <Button variant="outlined" type="submit" disabled={isDisabledCreateBtn}>
          Log In
        </Button>
      </form>
      <Link style={{ alignSelf: "center", fontWeight: "bold" }} href="/signUp">
        {/* TODO:ROUTE: autofill username box after redirect*/}
        Create Account
      </Link>
    </SimplePageWrap>
  );
}

import { Brightness4, Brightness7, BrightnessAuto } from "@mui/icons-material";
import { Button, ButtonGroup, IconButton } from "@mui/material";
import { useDevTestFunction, useNotYet } from "dev/devHooksHOC";
import { usePaletteDarkMode } from "utils/hooks/usePaletteDarkMode";

export function Navbar() {
  const notYet = useNotYet();
  const { paletteMode, togglePaletteDarkMode } = usePaletteDarkMode();
  const test = useDevTestFunction();
  return (
    <div>
      <IconButton color="primary" onClick={togglePaletteDarkMode}>
        {paletteMode === "dark" ? (
          <Brightness4 />
        ) : paletteMode === "light" ? (
          <Brightness7 />
        ) : (
          <BrightnessAuto />
        )}
      </IconButton>
      <ButtonGroup
        variant="contained"
        color="primary"
        aria-label="primary button group"
      >
        {process.env.NODE_ENV === "development" && (
          <Button onClick={test}>Test</Button>
        )}
        {/* // TODO: real Product href */}
        <Button href="/about">Products</Button>
        <Button onClick={notYet}>Pricing</Button>
        <Button onClick={notYet}>Documentation</Button>
        <Button href="/community">Community</Button>
      </ButtonGroup>
    </div>
  );
}

import { List, ListItemButton, ListItemText, Typography } from "@mui/material";
import { EmailBox } from "component/EmailBox";
import { useState } from "react";
import { CompoundPageWrap } from "UI/CompoundPageWrap";
import { SingleBodyWithTitle } from "UI/SingleBodyWithTitle";

export function SupportPage() {
  // TODO:WAIT: MUI dropdown: Use Dropdown instead of menu. (waiting for MUI update)
  const [isShowEmailBox, setIsShowEmailBox] = useState<boolean>(false);

  function showMail() {
    setIsShowEmailBox(true);
  }
  return (
    <CompoundPageWrap>
      <SingleBodyWithTitle title="Contact Us">
        <Typography variant="body1">Get help from SnippetHub.</Typography>
        <br />
        <Typography variant="body1">
          Please contact us if you need help with your SnippetHub account, or if
          you need to report malicious or abusive behavior in a snippet in the
          registry.
        </Typography>
        <br />
        <Typography variant="h6">How can we help?</Typography>
        <br />
        <List>
          <ListItemButton
            component="a"
            href="https://github.com/Pablion/snippet-hub/issues"
          >
            <ListItemText primary="General: I need help with something (like Feature Request, Bug Report)" />
          </ListItemButton>
          <ListItemButton onClick={showMail}>
            <ListItemText primary="Account: I have an account or billing issue" />
          </ListItemButton>
          <ListItemButton onClick={showMail}>
            <ListItemText primary="Report: I'm reporting spam, abuse or a security issue" />
          </ListItemButton>
        </List>
        <EmailBox {...{ isShowEmailBox }} />
      </SingleBodyWithTitle>
    </CompoundPageWrap>
  );
}

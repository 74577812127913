// SecondaryHeader.tsx of [snippethub-web], at 210916

import { IconButton, Link, styled } from "@mui/material";
import { Navbar } from "./Navbar";
import { Favorite } from "@mui/icons-material";
import { BorderTopGradient } from "UI/BorderTopGradient";

interface SecondaryHeaderProps {}
export function SecondaryHeader(props: SecondaryHeaderProps) {
  return (
    <WithUpperBoarder>
      <SecondaryHeaderWrap>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "baseline",
          }}
        >
          With
          <IconButton size="small" href="/web-thank-list">
            <Favorite />
          </IconButton>
          <Link href="https://github.com/Pablion">@Pablion</Link>&nbsp; made
          this page, plagiarizing &nbsp;
          <Link href="https://www.npmjs.com/">NPM</Link> &nbsp; 😜.
        </div>
        <Navbar />
      </SecondaryHeaderWrap>
    </WithUpperBoarder>
  );
}

/* ======== STYLED ======== */
const WithUpperBoarder = styled(BorderTopGradient)({
  padding: "8px 0px",
});
const SecondaryHeaderWrap = styled("div")({
  margin: "0 40px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});

// MainHeader.tsx of [snippethub-web], at 210915

import { Box, styled } from "@mui/material";
import { Logo } from "component/Logo";
import { useEffect } from "react";
import { useSelector } from "redux/store";
import { AuthButtons } from "./AuthButtonGroup";
import { SearchField } from "./SearchField";
import { UserIconDropdown } from "./UserIconDropdown";

export function MainHeader() {
  const { user: currUser } = useSelector((state) => state.user);

  useEffect(() => {
    return () => {};
    /* this is a lifecycle */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainHeaderWrap>
      <Logo style={{ padding: "8px 2rem 0 0" }} variant="h2" />
      <SearchField />
      <Box sx={{ mx: 1 }} />
      {currUser ? (
        <UserIconDropdown style={{ marginLeft: "1rem" }} withChevron />
      ) : (
        <AuthButtons />
      )}
    </MainHeaderWrap>
  );
}

/* ======== STYLED ======== */
const MainHeaderWrap = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px 2rem",
  borderTop: `1px solid ${theme.palette.divider}`,
}));

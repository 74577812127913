// RecentSnippets.tsx of [snippethub-web], at 210916

import { Typography } from "@mui/material";
import { WideDiv, WideDivHeader } from "UI/Styled";
import { AccessTime } from "@mui/icons-material/";

export function RecentSnippets() {
  return (
    <WideDiv>
      <WideDivHeader
        icon={<AccessTime />}
        title="Recently updated Snippets"
        divider="var(--three-color-gradient) 1"
      ></WideDivHeader>
      <div style={{ margin: "10px" }}>
        <Typography>
          Lorem ipsum @2021-09-15 10:08:21 dolor sit sed do ut et magna . Ut ad
          minim exercitation nisi ut ex ea . dolor in in esse eu non , in culpa
          qui anim id est . Lorem ipsum @2021-09-15 10:08:21 dolor sit sed do ut
          et magna . Ut ad minim exercitation nisi ut ex ea . dolor in in esse
          eu non , in culpa qui anim id est .
        </Typography>
        <Typography>
          Lorem ipsum @2021-09-15 10:08:21 dolor Lorem ipsum @2021-09-15
          10:08:21 dolor sit sed do ut et magna . Ut ad minim exercitation nisi
          ut ex ea . dolor in in esse eu non , in culpa qui anim id est . Lorem
          ipsum @2021-09-15 10:08:21 dolor sit sed do ut et magna . Ut ad minim
          exercitation nisi ut ex ea . dolor in in esse eu non , in culpa qui
          anim id est .
        </Typography>
        <Typography>
          Lorem ipsum @2021-09-15 10:08:21 dolor Lorem ipsum @2021-09-15
          10:08:21 dolor sit sed do ut et magna . Ut ad minim exercitation nisi
          ut ex ea . dolor in in esse eu non , in culpa qui anim id est . Lorem
          ipsum @2021-09-15 10:08:21 dolor sit sed do ut et magna . Ut ad minim
          exercitation nisi ut ex ea . dolor in in esse eu non , in culpa qui
          anim id est .
        </Typography>
        <Typography>
          Lorem ipsum @2021-09-15 10:08:21 dolor Lorem ipsum @2021-09-15
          10:08:21 dolor sit sed do ut et magna . Ut ad minim exercitation nisi
          ut ex ea . dolor in in esse eu non , in culpa qui anim id est . Lorem
          ipsum @2021-09-15 10:08:21 dolor sit sed do ut et magna . Ut ad minim
          exercitation nisi ut ex ea . dolor in in esse eu non , in culpa qui
          anim id est .
        </Typography>
        <Typography>
          Lorem ipsum @2021-09-15 10:08:21 dolor Lorem ipsum @2021-09-15
          10:08:21 dolor sit sed do ut et magna . Ut ad minim exercitation nisi
          ut ex ea . dolor in in esse eu non , in culpa qui anim id est . Lorem
          ipsum @2021-09-15 10:08:21 dolor sit sed do ut et magna . Ut ad minim
          exercitation nisi ut ex ea . dolor in in esse eu non , in culpa qui
          anim id est .
        </Typography>
        <Typography>
          Lorem ipsum @2021-09-15 10:08:21 dolor Lorem ipsum @2021-09-15
          10:08:21 dolor sit sed do ut et magna . Ut ad minim exercitation nisi
          ut ex ea . dolor in in esse eu non , in culpa qui anim id est . Lorem
          ipsum @2021-09-15 10:08:21 dolor sit sed do ut et magna . Ut ad minim
          exercitation nisi ut ex ea . dolor in in esse eu non , in culpa qui
          anim id est .
        </Typography>
      </div>
    </WideDiv>
  );
}

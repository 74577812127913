// SearchField.tsx of [snippethub-web], at 210926

import React from "react";
import { Search as SearchIcon } from "@mui/icons-material";
import { Button, InputAdornment, styled, TextField } from "@mui/material";
import { withNotYet } from "dev/devHooksHOC";

interface SearchFieldProps {}
interface SearchFieldState {}
class SearchField extends React.Component<
  SearchFieldProps & { notYet: () => void },
  SearchFieldState
> {
  render() {
    return (
      <SearchFieldWrap>
        <TextField
          fullWidth
          size="small"
          placeholder="Search…"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
        <Button variant="contained" size="large" onClick={this.props.notYet}>
          Search
        </Button>
      </SearchFieldWrap>
    );
  }
}

const exportedSearchField = withNotYet(SearchField);
export { exportedSearchField as SearchField };

/* ======== STYLED ======== */
const SearchFieldWrap = styled("div")({
  flex: "1 1 auto",
  height: "3rem",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
});

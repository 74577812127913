import { Divider, Link, Typography } from "@mui/material";
import { CompoundPageWrap } from "UI/CompoundPageWrap";
import { SingleBodyWithTitle } from "UI/SingleBodyWithTitle";

export function DisputesResolution() {
  return (
    <CompoundPageWrap>
      <SingleBodyWithTitle title="Dispute Resolution">
        <Typography variant="body1">
          This document describes the steps that you should take to resolve
          naming disputes with other SnippetHub publishers. Nothing in this
          document should be interpreted to contradict any aspect of the Snippet
          Hub Code of Conduct or Open-Source Terms.
        </Typography>
        <Typography variant="h5" sx={{ mt: 6, fontWeight: 500 }}>
          tl;dr
        </Typography>{" "}
        <Divider sx={{ mb: 3 }} />
        <Typography variant="body1">
          1. Open a support ticket at{" "}
          <Link href="https://SnippetHub.dev/support">
            https://SnippetHub.dev/support
          </Link>
          <br />
          2. Explain why you require a bundle, org, or username transferred
          Support will address your request.
          <br />
          3. Please note submitting a report does not guarantee the transfer of
          a bundle, org, or username.
        </Typography>
        <Typography variant="h5" sx={{ mt: 6, fontWeight: 500 }}>
          When not to use this process
        </Typography>
        <Divider sx={{ mb: 3 }} />
        <Typography variant="body1">
          We are not currently accepting dispute requests to "Report Squatting"
          as we re-evaluate and update the overall dispute process.
        </Typography>
      </SingleBodyWithTitle>
    </CompoundPageWrap>
  );
}

import { BoxProps, Box } from "@mui/material";

interface UserSnippetsProps extends BoxProps {
  username: string;
}
export function UserSnippets(props: UserSnippetsProps) {
  return (
    <Box>
      Snippets uploaded by user <code>'{props.username}'</code> should be shown
      here, but this part is not finished yet.
    </Box>
  );
}

import { CompoundPageWrap } from "UI/CompoundPageWrap";
import { SingleBodyWithTitle } from "UI/SingleBodyWithTitle";

export function CommunityPage() {
  return (
    <CompoundPageWrap>
      <SingleBodyWithTitle title="Snippet Hub Community">
        We'll have a discord server later.
      </SingleBodyWithTitle>
    </CompoundPageWrap>
  );
}

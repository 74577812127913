import React from "react";
import { useDispatch } from "react-redux";
import { showTopSnackbar } from "redux/snackbar";

const devEnv = process.env.NODE_ENV === "development";
export function useDevTestFunction() {
  const dispatch = useDispatch();
  function test() {
    if (devEnv) {
      alert("this is a test function for dev only");
      // import("secret/github").then(({ gh }) => {
      //   const reqIdUrl = `https://github.com/login/oauth/authorize?client_id=${gh.clientId}`;
      //   window.location.href = reqIdUrl;
      // });
      dispatch(showTopSnackbar("sample", "success"));
    } else {
      alert("You should not see this test button. Nothing happened.");
    }
  }
  return test;
}

// TODO: This should not be called.
export function useNotYet() {
  const dispatch = useDispatch();
  return function notYet() {
    dispatch(showTopSnackbar("This function is not yet available", "error"));
  };
}

export function withNotYet<P extends object>(
  Component: React.ComponentType<P & { notYet: () => void }>
) {
  return function ComponentWithNotYet(props: P) {
    const notYet = useNotYet();
    return <Component {...props} notYet={notYet} />;
  };
}

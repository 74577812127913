import { BoxProps, Box } from "@mui/material";

interface UserBundlesProps extends BoxProps {
  username: string;
}
export function UserBundles(props: UserBundlesProps) {
  return (
    <Box>
      Bundles uploaded by user <code>'{props.username}'</code> should be shown
      here, but this part is not finished yet.
    </Box>
  );
}

/*
 * This file exports ONE function {signUp}
 * Throws an error if signUp fails.
 */
/**
 * @param  {} {username
 * @param  {} password
 * @param  {} email
 * @param  {SignUpProp} }
 * @returns Promise<SignUpResponse>
 */

import {
  createUserWithEmailAndPassword,
  updateProfile,
  User,
} from "firebase/auth";
import { auth } from "./initFireAuth";
import { FireAuthError, FireAuthErrorCode } from "../fire-constants";
import { readPath } from "../realtime-db/basic/readPath";
import { writePath } from "../realtime-db/basic/writePath";

/* ======== TYPING ======== */
interface SignUpProp {
  username: string;
  password: string;
  email: string;
}

export async function signUp({
  username,
  password,
  email,
}: SignUpProp): Promise<User> {
  try {
    const uid = await readPath("username2id/" + username);
    if (uid) {
      throw new FireAuthError("X_USERNAME_EXISTS");
    }
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    ); // Signed in
    const user = userCredential.user;
    updateProfile(user, { displayName: username });
    await writePath("users/" + user.uid + "/metadata", { username, email });
    await writePath("username2id/" + username, user.uid);
    return user;
  } catch (err: any) {
    const { code } = err;
    if (code === FireAuthErrorCode.X_USERNAME_EXISTS) {
      throw err;
    }
    if (code === FireAuthErrorCode.EMAIL_EXISTS) {
      throw err;
    }
    console.error("UNKNOWN Error in Sign up:", err);
    throw err;
  }
}

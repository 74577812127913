import { Divider, Link, Typography } from "@mui/material";
import { CompoundPageWrap } from "UI/CompoundPageWrap";
import { SingleBodyWithTitle } from "UI/SingleBodyWithTitle";

export function PrivacyPolicy() {
  return (
    <CompoundPageWrap>
      <SingleBodyWithTitle title="Dispute Resolution">
        <Typography variant="body1">
          A a minimum, absolutely every Snippet Hub user should understand:
          <br />
          The Snippet Hub is for making snippets available to everyone online.
          <br />
          But: Snippets comes from people, and says something about us.
          <br />
          So: Think carefully about what bundles to publish, what data you put
          in those bundles, and what others might do with that data.
          <br />
          When you create an account, certain contact information is displayed
          publicly in the Snippet Hub platform.
          <br />
          And when you upload a package, your name and contact information may
          become associated with that package.
          <br />
          If you find yourself in a jam, open a{" "}
          <Link href="https://snippethub.dev/support">support ticket</Link>.
        </Typography>
        <Typography variant="h5" sx={{ mt: 6, fontWeight: 500 }}>
          How does Snippet Hub collect data about me?
        </Typography>{" "}
        <Divider sx={{ mb: 3 }} />
        <Typography variant="body1">
          Snippet Hub collects data about you:
          <br />
          {"> "}when you use the Snippet Hub extension or another program to
          access the Snippet Hub public registry, Enterprise registries that
          Snippet Hub hosts, private bundles, such as when you're publishing a
          package, and APIs for functionality like account and permissions
          management
          <br />
          {"> "}when you browse the Snippet Hub website, snippethub.dev
          <br />
          {"> "}when you use either the Snippet Hub Extension command or the
          website to create an Snippet Hub account, update your account, and
          sign up for Snippet Hub services
          <br />
          {"> "}when you send support, privacy, legal, and other requests to
          Snippet Hub
          <br />
          {"> "}when working with and researching current and potential
          customers
          <br />
          When researching potential customers, Snippet Hub staff sometimes
          search the public World Wide Web or paid business databases.
          Otherwise, Snippet Hub doesn't buy or receive data about you from data
          brokers or other private services.
          <br />
          Snippet Hub may inadvertently collect data about you if it is included
          in snippet bundles that you or others upload.
        </Typography>
        <Typography variant="h5" sx={{ mt: 6, fontWeight: 500 }}>
          When not to use this process
        </Typography>
        <Divider sx={{ mb: 3 }} />
        <Typography variant="body1">
          We are not currently accepting dispute requests to "Report Squatting"
          as we re-evaluate and update the overall dispute process.
        </Typography>
      </SingleBodyWithTitle>
    </CompoundPageWrap>
  );
}

import { KeyboardArrowRight } from "@mui/icons-material";
import { List, ListItemButton, ListItemText, Typography } from "@mui/material";
import { CompoundPageWrap } from "UI/CompoundPageWrap";
import { SingleBodyWithTitle } from "UI/SingleBodyWithTitle";

export function PolicyPage() {
  return (
    <CompoundPageWrap>
      <SingleBodyWithTitle title="SnippetHub Policies">
        <Typography variant="body1">
          These are the legal policies of SnippetHub.dev (npm is redirecting to
          another subdomain, <code>doc</code>)
        </Typography>
        <List dense>
          <ListItemButton component="a" href="/policies/terms">
            <KeyboardArrowRight />
            <ListItemText primary="Terms of Use" />
          </ListItemButton>
          <ListItemButton component="a" href="/policies/conduct">
            <KeyboardArrowRight />
            <ListItemText primary="Code of Conduct" />
          </ListItemButton>{" "}
          <ListItemButton component="a" href="/policies/disputes">
            <KeyboardArrowRight />
            <ListItemText primary="Bundle Name Disputes" />
          </ListItemButton>
          <ListItemButton component="a" href="/policies/privacy">
            <KeyboardArrowRight />
            <ListItemText primary="Privacy Policy" />
          </ListItemButton>
        </List>
      </SingleBodyWithTitle>
    </CompoundPageWrap>
  );
}

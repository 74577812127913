// storage.ts of [snippethub-web], at 210921
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import { themingReducer } from "./theming";
import { snackbarReducer, snackbarMiddleware } from "./snackbar";
import { userMiddleware, userReducer } from "./user";

/* ======== CONFIGURE STORE ======== */

/* ======== NOT TS ======== */

// import { combineReducers } from "redux";
// const reducer = combineReducers({
//   theming: themingReducer,
// });
// export const store = createStore(reducers, composeWithDevTools());
/* ======== STORE ======== */
export const store = configureStore({
  reducer: {
    theming: themingReducer,
    snackbar: snackbarReducer,
    user: userReducer,
  },
  middleware: [snackbarMiddleware, userMiddleware],
});
// FOR TS use configureStore
/* ======== tried to dynamically compose ======== */
// var store;
// const devEnv = process.env.NODE_ENV === "development";
// if (devEnv) {
//   import("redux-devtools-extension").then(({ composeWithDevTools }) => {
//     store = createStore(reducers, composeWithDevTools());
//   });
// } else {
//   store = createStore(reducers);
// }
// export { store };
// export (store as RootStoreState)  as "store";
/* ======== MIDDLEWARE ======== */
// NONE
/* ======== TYPING ======== */
export type RootStoreState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
const useAppSelector: TypedUseSelectorHook<RootStoreState> = useSelector;
export { useAppSelector as useSelector };

import { FirebaseError } from "@firebase/util";
/*
 * Properties of FireAuthErrorCode with starting "X" means customized.
 * Other properties are from AuthErrorCode, url on next line.
 * https://firebase.google.com/docs/reference/js/auth.md#actioncodeoperation
 *
 */

export enum FireAuthErrorCode {
  /* from AuthErrorCodes in node_modules\@firebase\auth\dist\auth-public.d.ts */
  // TODO:WAIT: Link this to AuthErrorCodes with import
  EMAIL_EXISTS = "auth/email-already-in-use",
  INVALID_PASSWORD = "auth/wrong-password",
  NEED_CONFIRMATION = "auth/account-exists-with-different-credential",
  TOO_MANY_ATTEMPTS_TRY_LATER = "auth/too-many-requests",
  /* Customized */
  X_INVALID_USERNAME = "auth/x-username-does-not-exist",
  X_USERNAME_EXISTS = "auth/x-username-already-in-use",
}

export enum FireAuthErrorMsg {
  TOO_MANY_ATTEMPTS_TRY_LATER = "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.",
}
export class FireAuthError extends FirebaseError {
  public static readonly genDefaultMessage = (
    code: keyof typeof FireAuthErrorCode
  ) => `Firebase: Error (${FireAuthErrorCode[code]}).`;

  constructor(errorCode: Exclude<keyof typeof FireAuthErrorCode, "prototype">) {
    if (errorCode === "TOO_MANY_ATTEMPTS_TRY_LATER") {
      super(
        FireAuthErrorCode[errorCode],
        `Firebase: ${FireAuthErrorMsg.TOO_MANY_ATTEMPTS_TRY_LATER} (${FireAuthErrorCode[errorCode]}).`
      );
    } else {
      super(
        FireAuthErrorCode[errorCode],
        FireAuthError.genDefaultMessage(errorCode)
      );
    }
    return this;
  }
}

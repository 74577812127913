import { Divider, Link, Typography } from "@mui/material";
import { CompoundPageWrap } from "UI/CompoundPageWrap";
import { SingleBodyWithTitle } from "UI/SingleBodyWithTitle";

export function CodeOfConduct() {
  return (
    <CompoundPageWrap>
      <SingleBodyWithTitle title="Snippet Hub Code of Conduct">
        <Typography variant="body1">
          Snippet Hub exists to facilitate sharing snippets, by making it easy
          for Visual Studio Code&copy; developers to publish and distribute
          snippets. Snippet Hub is a piece of technology, but more importantly,
          it is a community. We believe that our mission is best served in an
          environment that is friendly, safe, and accepting; free from
          intimidation or harassment. Towards this end, certain behaviors and
          practices will not be tolerated.
        </Typography>
        <Typography variant="h5" sx={{ mt: 6, fontWeight: 500 }}>
          tl;dr
        </Typography>{" "}
        <Divider sx={{ mb: 3 }} />
        <Typography variant="body1">
          {"> "}Be respectful.
          <br />
          {"> "}We're here to help
          <br />
          {"> "}Abusive behavior is never tolerated.
          <br />
          {"> "}Data published to Snippet Hub is hosted at the discretion of the
          service administrators, and may be removed.
          <br />
          {"> "}Violations of this code may result in swift and permanent
          expulsion from the Snippet Hub community.
          <br />
        </Typography>
        <Typography variant="h5" sx={{ mt: 6, fontWeight: 600 }}>
          Friendly Harassment-Free Space
        </Typography>{" "}
        <Divider sx={{ mb: 3 }} />
        <Typography variant="body1">
          We are committed to providing a friendly, safe and welcoming
          environment for all, regardless of gender identity, sexual
          orientation, ability, ethnicity, religion, age, physical appearance,
          body size, race, or similar personal characteristics. We ask that you
          please respect that people have differences of opinion regarding
          technical choices, and that every design or implementation choice
          carries a trade-off and numerous costs. There is seldom a single right
          answer. A difference of technology preferences is not a license to be
          rude. Disputes over package rights must be handled respectfully,
          according to the terms described in the{" "}
          <Link href="/policies/disputes">Disputes Policy</Link>. There is never
          a good reason to be rude over package name disputes.
          <br />
          <br />
          Any spamming, trolling, flaming, baiting, or other attention-stealing
          behavior is not welcome, and will not be tolerated.
          <br />
          <br />
          Harassing other users of the Service is never tolerated, whether via
          public or private media.
          <br />
          <br />
          Avoid using offensive or harassing package names, nicknames, or other
          identifiers that might detract from a friendly, safe, and welcoming
          environment for all.
          <br />
          <br />
          Harassment includes, but is not limited to: harmful or prejudicial
          verbal or written comments related to gender identity, sexual
          orientation, ability, ethnicity, religion, age, physical appearance,
          body size, race, or similar personal characteristics; inappropriate
          use of nudity, sexual images, and/or sexually explicit language in
          public spaces; threats of physical or non-physical harm; deliberate
          intimidation, stalking or following; harassing photography or
          recording; sustained disruption of talks or other events;
          inappropriate physical contact; and unwelcome sexual attention.
        </Typography>
        <Typography variant="h5" sx={{ mt: 6, fontWeight: 600 }}>
          Acceptable Use
        </Typography>{" "}
        <Divider sx={{ mb: 3 }} />
        <Typography variant="body1">
          The Service administrators reserve the right to make judgment calls
          about what is and isn't appropriate in published packages, package
          names, user and organization names, and other public content.
        </Typography>
      </SingleBodyWithTitle>
    </CompoundPageWrap>
  );
}

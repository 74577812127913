import { Action } from "redux";

/* ======== CONST ======== */
export const THEMING_types = {
  THEMING_CHANGE_MODE: "THEMING_CHANGE_MODE",
  THEMING_SET_PREFERS_DARK_MODE: "THEMING_SET_PREFERS_DARK_MODE",
};

// export enum modeName {
//   LIGHT = "LIGHT",
//   DARK = "DARK",
// }

interface ThemingInterface {
  prefersDarkMode: boolean;
  isDarkMode: boolean;
}

interface ThemingAction extends Action {
  payload?: boolean;
}
const initialTheming = { prefersDarkMode: true, isDarkMode: true };

/* ======== REDUCER ======== */
export const themingReducer = (
  state: ThemingInterface = initialTheming,
  action: ThemingAction
): ThemingInterface => {
  switch (action.type) {
    case THEMING_types.THEMING_CHANGE_MODE:
      return { ...state, isDarkMode: !state.isDarkMode };
    case THEMING_types.THEMING_SET_PREFERS_DARK_MODE:
      return {
        ...state,
        isDarkMode: action.payload!,
        prefersDarkMode: action.payload!,
      };
    default:
      return state;
  }
};

/* ======== ACTIONS ======== */
export const changeThemingMode = () => ({
  type: THEMING_types.THEMING_CHANGE_MODE,
});
export const setPrefersDarkMode = (prefersDarkMode: boolean) => ({
  type: THEMING_types.THEMING_CHANGE_MODE,
  payload: prefersDarkMode,
});

/* ======== MIDDLEWARE ======== */
export const themingMiddleware = (store: any) => (next: any) => (
  action: Action
) => {
  return next(action);
};

// MainDiscover.tsx of [snippethub-web], at 210916

import { ButtonBase, styled, useTheme } from "@mui/material";
import { WideDiv, WideDivHeader } from "UI/Styled";
import { Search } from "@mui/icons-material";
import { useCallback, useMemo, useState } from "react";
import {
  FlipToFront,
  FlipToBack,
  Code,
  Description,
  LineStyle,
  Check,
  DevicesOther,
  BeachAccess,
  PhoneIphone,
  BorderInner,
  Adb,
  Functions,
} from "@mui/icons-material";
import { rescale } from "utils/uiHelper";
import { useNotYet } from "dev/devHooksHOC";

export function MainDiscover() {
  const notYet = useNotYet();
  /* ======== REACT ======== */
  const availableColors = useMemo(() => ["orange", "yellow", "lime"], []);

  const [btnColorIdx, setBtnColorIdx] = useState<0 | 1 | 2>(1);
  const genBtnHoverColor = useCallback(() => {
    setBtnColorIdx((prevIdx: 0 | 1 | 2) => {
      // const newIdx = (Math.ceil(Math.random() * 3) - 1) as 0 | 1 | 2;
      const newIdxAdd = Math.random() > 0.5 ? 1 : 2;
      return ((prevIdx + newIdxAdd) % 3) as 0 | 1 | 2;
    });
  }, []);

  /* ======== MUI ======== */
  const theme = useTheme();
  const rescaledButtonFontSize = rescale(
    theme.typography.button.fontSize,
    0.88
  );
  const buttonsInfo = useMemo<{ [key: string]: JSX.Element }>(() => {
    const btnFontSize = { fontSize: rescaledButtonFontSize };
    return {
      "Front-end": <FlipToFront sx={btnFontSize} />,
      "Back-end": <FlipToBack sx={btnFontSize} />,
      CLI: <Code sx={btnFontSize} />,
      Documentation: <Description sx={btnFontSize} />,
      CSS: <LineStyle sx={btnFontSize} />,
      Testing: <Check sx={btnFontSize} />,
      IoT: <DevicesOther sx={btnFontSize} />,
      Coverage: <BeachAccess sx={btnFontSize} />,
      Mobile: <PhoneIphone sx={btnFontSize} />,
      Frameworks: <BorderInner sx={btnFontSize} />,
      Robotics: <Adb sx={btnFontSize} />,
      Math: <Functions sx={btnFontSize} />,
    };
  }, [rescaledButtonFontSize]);

  /* ======== STYLED DYNAMICALLY ======== */
  /* re-render all btn after hover event */
  const StyledButtonBase = styled(ButtonBase)({
    border: `1px solid ${theme.palette.divider}`,
    borderBottom: `2px solid ${theme.palette.divider}`,
    backgroundColor: "transparent",
    transition: "background-color 0.2s ease-out",
    flex: "1",
    alignItems: "center",
    whiteSpace: "nowrap",
    "&>*": {
      padding: "25px 35px",
      fontSize: rescaledButtonFontSize,
    },
    "&:hover": {
      backgroundColor: `var(--${availableColors[btnColorIdx]}0-a50)`,
      borderBottomColor: `var(--${availableColors[btnColorIdx]}1)`,
      transition: "background-color 0.1s linear",
    },
  });

  return (
    <WideDiv>
      <WideDivHeader
        icon={<Search />}
        title="Discover Snippets"
        divider="var(--yellow0)"
      ></WideDivHeader>
      <ButtonAreaWrap>
        {Object.entries(buttonsInfo).map(([key, icon]) => {
          return (
            <StyledButtonBase
              focusRipple
              onMouseLeave={genBtnHoverColor}
              key={key}
              onClick={notYet}
            >
              <div aria-label="flex-element">
                <div
                  style={{
                    display: "inline",
                    marginRight: "10px",
                  }}
                >
                  {icon}
                </div>
                {key}
              </div>
            </StyledButtonBase>
          );
        })}
      </ButtonAreaWrap>
    </WideDiv>
  );
}

/* ======== STYLED ======== */
const ButtonAreaWrap = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  margin: "10px",
  justifyContent: "center",
  gap: "10px",
});

import { Divider, Typography } from "@mui/material";
import { CompoundPageWrap } from "UI/CompoundPageWrap";
import { SingleBodyWithTitle } from "UI/SingleBodyWithTitle";

export function TermsAndLicense() {
  return (
    <CompoundPageWrap>
      <SingleBodyWithTitle title="Terms and Licenses">
        <Typography variant="body1">
          SnippetHub.dev offers software and services under a few different
          licenses and terms of use.
        </Typography>
        <Typography variant="h5" sx={{ mt: 6, fontWeight: 600 }}>
          Free to use SnippetHub.dev services
        </Typography>{" "}
        <Divider />
        <Typography variant="body1">
          All products are free to use right now.
        </Typography>
      </SingleBodyWithTitle>
    </CompoundPageWrap>
  );
}

import { ref, set } from "firebase/database";
import { rtDb } from "./initRealtimeDB";

export async function writePath(path: string, value: string | object | null) {
  try {
    await set(ref(rtDb, path), value);
    return true;
  } catch (error: any) {
    if (error.message === "PERMISSION_DENIED: Permission denied") {
      console.error(
        `Error on writing "${JSON.stringify(value)}", to "${path}"`
      );
      throw Error(`PERMISSION_DENIED: Write to realtime database`);
    } else {
      console.error("UNKNOWN_ERROR: Write to realtime database");
      console.error(error);
      throw error;
    }
  }
}

import { UserBundles } from "component/acc-comps/UserBundles";
import { useParams } from "react-router-dom";
import { CompoundPageWrap } from "UI/CompoundPageWrap";
import { SingleBodyWithTitle } from "UI/SingleBodyWithTitle";

// TODO: check isValidUser, use real itemNumber, dynamic(s)
const isValidUser = true;
const itemNumber = 7;

export function UserBundlePage() {
  const { username } = useParams<{ username: string }>();

  return (
    <CompoundPageWrap>
      {isValidUser && (
        <SingleBodyWithTitle title={`${itemNumber} bundle(s)`}>
          <UserBundles username={username} />
          TODO: check isValidUser, use real itemNumber, dynamic (s)
        </SingleBodyWithTitle>
      )}
    </CompoundPageWrap>
  );
}

import { Button, ButtonGroup } from "@mui/material";
import { GhOAuthBtn } from "component/oauth-comps/GhOAuthBtn";
import { GoogleOAuthBtn } from "component/oauth-comps/GoogleOAuthBtn";

export function AuthButtons() {
  return (
    <ButtonGroup size="large">
      <Button href="/signUp">Sign Up</Button>
      <Button href="/login" sx={{ mr: 1 }}>
        Log In
      </Button>
      <GhOAuthBtn />
      <GoogleOAuthBtn />
    </ButtonGroup>
  );
}

/*
 * This file exports ONE function {login}
 * Throws an error if login fails.
 */
/**
 * @param  LoginParams.username : string
 * @param  LoginParams.password : string
 * @returns Promise<User>
 */
import { auth } from "./initFireAuth";
import { signInWithEmailAndPassword, User } from "firebase/auth";
import { getEmailByUsername } from "../realtime-db/getEmailByUsername";
interface LoginParams {
  username: string;
  password: string;
}

export async function login({
  username,
  password,
}: LoginParams): Promise<User> {
  const email = await getEmailByUsername(username);
  if (!email) {
    throw new Error("UNKNOWN ERROR");
  }
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;
    console.log("user : ", user);
    return user;
  } catch (err: any) {
    throw err;
  }
}

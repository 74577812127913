import { AlertColor } from "@mui/material";

export {
  SNACKBAR_types,
  snackbarReducer,
  connectTopSnackbar,
  showTopSnackbar,
  hideTopSnackbar,
  snackbarMiddleware,
};
/* ======== CONST ======== */
enum SNACKBAR_types {
  SNACKBAR_CONNECT = "SNACKBAR_CONNECT",
  SNACKBAR_SHOW = "SNACKBAR_SHOW",
  SNACKBAR_HIDE = "SNACKBAR_HIDE",
}

interface SnackbarInterface {
  isConnectedSnackbar: boolean;
  isOpenTopSnackBar: boolean;
  snackBarMessage: string;
  snackBarSeverity?: AlertColor;
}

const initialSnackbarState = {
  isConnectedSnackbar: false,
  isOpenTopSnackBar: false,
  snackBarMessage: "",
  snackBarSeverity: undefined,
};

/* ======== ACTIONS ======== */
interface ActionConnectTopSnackbar {
  type: SNACKBAR_types.SNACKBAR_CONNECT;
}
function connectTopSnackbar(): ActionConnectTopSnackbar {
  return {
    type: SNACKBAR_types.SNACKBAR_CONNECT,
  };
}

interface ActionShowTopSnackbar {
  type: SNACKBAR_types.SNACKBAR_SHOW;
  payload: { message: string; severity?: AlertColor };
}
function showTopSnackbar(
  message: string,
  severity?: AlertColor
): ActionShowTopSnackbar {
  return {
    type: SNACKBAR_types.SNACKBAR_SHOW,
    payload: { message, severity },
  };
}

interface ActionHideTopSnackbar {
  type: SNACKBAR_types.SNACKBAR_HIDE;
}
function hideTopSnackbar(): ActionHideTopSnackbar {
  return {
    type: SNACKBAR_types.SNACKBAR_HIDE,
  };
}

/* ======== REDUCER ======== */
type SnackbarAction =
  | ActionConnectTopSnackbar
  | ActionShowTopSnackbar
  | ActionHideTopSnackbar;

const snackbarReducer = (
  state: SnackbarInterface = initialSnackbarState,
  action: SnackbarAction
): SnackbarInterface => {
  switch (action.type) {
    case SNACKBAR_types.SNACKBAR_CONNECT: {
      if (state.isConnectedSnackbar) {
        return state;
      }
      return { ...state, isConnectedSnackbar: true };
    }
    case SNACKBAR_types.SNACKBAR_SHOW: {
      return {
        ...state,
        isOpenTopSnackBar: true,
        snackBarMessage: action.payload.message,
        snackBarSeverity: action.payload.severity,
      };
    }
    case SNACKBAR_types.SNACKBAR_HIDE: {
      // return { ...state, isOpenTopSnackBar: false, snackBarMessage: "" };
      return { ...state, isOpenTopSnackBar: false };
      // TODO: Remove TopSnackbar first, then text.
    }
    default:
      return state;
  }
};

/* ======== MIDDLEWARE ======== */
const snackbarMiddleware =
  (store: any) => (next: any) => (action: SnackbarAction) => {
    if (action.type in SNACKBAR_types) {
      if (action.type !== SNACKBAR_types.SNACKBAR_CONNECT) {
        const isConnected = store.getState().snackbar.isConnectedSnackbar;
        if (!isConnected) {
          console.error("top level snackbar not connected in [snackbar]");
          // just for security, no other purpose.
        }
      }
    }
    return next(action);
  };

import {
  Box,
  Button,
  Divider,
  Link,
  Step,
  StepLabel,
  Stepper,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { useNotYet } from "dev/devHooksHOC";
import { SimplePageWrap } from "UI/SimplePageWrap";

function SFTextField({ sx, ...props }: TextFieldProps) {
  return <TextField size="small" fullWidth {...props} />;
} //TODO MOD-IZE

const username = "sample_username";
export function CreateOrgPage() {
  const notYet = useNotYet();
  return (
    <SimplePageWrap>
      <Stepper activeStep={0}>
        <Step completed={false}>
          <StepLabel>CREATE AN ORG</StepLabel>
        </Step>
        <Step completed={false}>
          <StepLabel>INVITE MEMBERS</StepLabel>
        </Step>
      </Stepper>
      <Divider style={{ margin: "20px 0" }} variant="fullWidth" />
      <Typography variant="h5">Create a New Organization</Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        <Typography>Name</Typography>
        <Typography variant="body2">https://npmjs.com/org/</Typography>
      </Box>
      <SFTextField />
      <Typography>Choose wisely, this cannot be changed.</Typography>

      <Divider style={{ margin: "20px 0" }} variant="fullWidth" />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography>Unlimited private snippets and bundles</Typography>
          <Typography variant="body2">$1 per month</Typography>
        </Box>
        <Box>
          <Button variant="outlined" onClick={notYet}>
            Buy
          </Button>
        </Box>
      </Box>
      <Divider style={{ margin: "20px 0" }} variant="fullWidth" />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography>Unlimited public snippets and bundles</Typography>
          <Typography variant="body2">Free</Typography>
        </Box>
        <Box>
          <Button variant="outlined" onClick={notYet}>
            Create
          </Button>
        </Box>
      </Box>
      <Divider style={{ margin: "20px 0" }} variant="fullWidth" />
      {/* //TODO: Should be a new card */}
      <Divider style={{ margin: "20px 0" }} variant="fullWidth" />
      <Typography variant="h5">Optional</Typography>
      <Typography variant="subtitle1">
        Convert <code>{`@${username}`}</code> into an org.
      </Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography>
            You can convert your existing SnippetHub account in just a few
            steps.
          </Typography>
        </Box>
        <Box>
          <Button variant="outlined" onClick={notYet}>
            Convert
          </Button>
        </Box>
      </Box>
      <Divider style={{ margin: "20px 0" }} variant="fullWidth" />
      <Typography>
        Need help?
        <Link href="/support">Contact support</Link>.
      </Typography>
    </SimplePageWrap>
  );
}

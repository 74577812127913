// TopLevelSnackbar.tsx of [snippethub-web], at 211012

/* usage:
 *  This component should be connected to redux store.
 *  setup:
 *    <TopSnackbar /> // in top layer like App.tsx
 *    use with redux/snackbar.ts
 *  interact:
 *    import { showTopSnackbar, hideTopSnackbar} from "redux/snackbar"
 *    showTopSnackbar(message,severity?);
 *    hideTopSnackbar();
 */

import { Alert, IconButton, Snackbar } from "@mui/material";
import { connectTopSnackbar, hideTopSnackbar } from "redux/snackbar";
import { useSelector } from "redux/store";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { Close } from "@mui/icons-material";
const DEFAULT_AUTO_HIDE_MS = 6000;

export function TopSnackbar() {
  const dispatch = useDispatch();
  const snackbar = useSelector((state) => state.snackbar);
  const { isOpenTopSnackBar, snackBarMessage, snackBarSeverity } = snackbar;
  const autoHideMs = 6000 ?? DEFAULT_AUTO_HIDE_MS;

  useEffect(() => {}, []);

  useEffect(() => {
    dispatch(connectTopSnackbar());
  }, [dispatch]);

  function closeHandler() {
    dispatch(hideTopSnackbar());
  }
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={isOpenTopSnackBar}
      autoHideDuration={autoHideMs}
      onClose={closeHandler}
      message={snackBarMessage}
    >
      {snackBarSeverity ? (
        <Alert
          variant="filled"
          severity={snackBarSeverity}
          action={
            <IconButton
              // variant="contained"
              color="inherit"
              size="small"
              onClick={closeHandler}
            >
              <Close />
            </IconButton>
          }
        >
          {snackBarMessage}
        </Alert>
      ) : undefined}
    </Snackbar>
  );
}

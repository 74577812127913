// IntroPage.tsx of [snippethub-web], at 211016

import { Header } from "../component/Header";
import { Footer } from "../component/Footer";
import {
  Box,
  Button,
  styled,
  Typography,
  TypographyProps,
} from "@mui/material";
import { withGinkgoBcg } from "UI/withGinkgoBcg";
import { withEyeBcg } from "UI/withEyeBcg";
import { WideSectionWrap } from "UI/Styled";
import { useNotYet } from "dev/devHooksHOC";

function BH4({ children, ...props }: TypographyProps) {
  // bold H4, also used for 1 h1.
  return (
    <Typography variant="h4" sx={{ fontWeight: 700, py: ".5em" }} {...props}>
      {children}
    </Typography>
  );
}

export function IntroPage() {
  const notYet = useNotYet();
  /* ======== MUI ======== */

  return (
    <>
      <Header />
      <FirstBcg>
        <WideSectionWrap>
          <BH4 variant="h1">Code amazingly fast</BH4>
          <SectionWrap sx={{ mt: 0 }}>
            <Typography variant="body1" sx={{ maxWidth: "32rem", m: "auto" }}>
              We're SnippetHub, not a incorporated nor a company. Our goal is to
              press less keys and remember less codes, hence a snippet exchange
              is needed. We offer this to the community for free.
            </Typography>
            <BH4>Take your IDE experience up a notch</BH4>
            <Typography variant="body1" sx={{ maxWidth: "32rem", m: "auto" }}>
              Get started today for free, or step up to SnippetPro to enjoy a
              premium experience, with priority on Feature Request and features
              like and private packages.
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                margin: "4rem 0",
              }}
            >
              <MainButton
                variant="contained"
                style={{
                  background:
                    "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
                  border: 0,
                  color: "white",
                  boxShadow: "0 0 .5rem .5rem rgba(255, 105, 135, .3)",
                }}
                href="/signUp"
              >
                Sign up for free
              </MainButton>
              <MainButton
                variant="outlined"
                style={{
                  backgroundColor: "var(--lime0)",
                  border: "1px solid black",
                  color: "black",
                }}
                onClick={notYet}
              >
                Learn about Pro
              </MainButton>
            </Box>
          </SectionWrap>
        </WideSectionWrap>
      </FirstBcg>
      <SectionWrap>
        <img
          src="https://static.npmjs.com/attachments/ck3uweazy72ye8874y9kkxnx1-gak.png"
          alt="an icon from npm"
        />
        <BH4>
          Bring the best coding experience to you, your team, and your company
        </BH4>
        <Typography variant="body1">
          Relied upon by more than 0 developers worldwide, SnippetHub is
          committed to making VScode© productive. Self defined trigger word(
          <code>prefix</code> in VScode©) helps you to "define" your own
          command. Our SnippetHub.dev will help you share, find and rate other
          snippets.
        </Typography>
      </SectionWrap>
      <ThirdBcg>
        <SectionWrap>
          <BH4>We value every keystroke</BH4>
          <Typography variant="body1">
            At SnippetHub(not a company), we're proud to save your time and run
            a sub community in the large open source community.
          </Typography>
        </SectionWrap>
      </ThirdBcg>
      <SectionWrap>
        <BH4>
          Gratefully serving everyone from solo developers to <s>NONE OF</s> the
          Fortune 500
        </BH4>
        <Typography variant="body1" marginBottom="2rem">
          We serve none of these companies below, image from npm.
        </Typography>
        <img
          width="100%"
          src="https://static.npmjs.com/attachments/ck49cu3ceh1sust74dkzkfn69-grey-logowall.png"
          alt="companies from npm"
        />
      </SectionWrap>
      <Footer />
    </>
  );
}

/* ======== STYLED ======== */
const FullWidthDiv = styled("div")({
  color: "black",
  width: "100%",
  textAlign: "center",
});
const FirstBcg = withGinkgoBcg(FullWidthDiv);
const ThirdBcg = withEyeBcg(FullWidthDiv);
const SectionWrap = styled("div")({
  maxWidth: "32rem",
  margin: "4rem auto",
  textAlign: "center",
});
const MainButton = styled(Button)({
  borderRadius: "100vmin",
  height: 48,
  padding: "2rem 3rem",
  maxWidth: "16rem",
  fontWeight: 700,
  fontSize: 20,
});

// import { auth } from "./initFireAuth";

export async function resetPasswordWithLink({
  usernameOrEmail,
}: {
  usernameOrEmail: string;
}) {
  alert(usernameOrEmail);
  alert("this is not working");
  return false;
  // auth.sendPasswordResetEmail();
}

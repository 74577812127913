import { AccountBox } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { useSelector } from "redux/store";

export function UserAvatar() {
  const theme = useTheme();
  const { user: currUser } = useSelector((state) => state.user);

  const jdenticonSrc =
    currUser?.photoURL ??
    (currUser?.displayName
      ? `https://avatars.dicebear.com/api/jdenticon/${currUser?.displayName}.svg`
      : undefined);
  const titleText = currUser?.photoURL
    ? currUser.displayName ?? currUser?.email!
    : `generated by https://avatars.dicebear.com/styles/jdenticon`;
  return (
    <>
      {jdenticonSrc ? (
        <img title={titleText} src={jdenticonSrc} alt="user-icon" />
      ) : (
        <AccountBox style={{ fontSize: theme.typography.h3.fontSize }} />
      )}{" "}
    </>
  );
}

// SimplePageWrap.tsx of [snippethub-web], at 210926

import { Paper, styled } from "@mui/material";
import { BorderTopGradient } from "./BorderTopGradient";
import { Logo } from "component/Logo";
import React from "react";

export function SimplePageWrap({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) {
  return (
    <BorderTopGradient>
      <FullWidthWrap>
        <Logo
          style={{
            margin: "80px 0 40px",
          }}
          variant="h1"
        />
        <ChildrenWrap elevation={4}>{children}</ChildrenWrap>
      </FullWidthWrap>
    </BorderTopGradient>
  );
}

/* ======== STYLED ======== */
const FullWidthWrap = styled("div")({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});
const ChildrenWrap = styled(Paper)({
  marginBottom: "160px",
  padding: "2em",
  width: "30em",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "stretch",
  "& > *": { margin: ".5rem", maxWidth: "26em" /* 30-2*2 */ },
  // apply same to "& > form"
  "& > form": {
    width: "30em",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "stretch",
  },
  "& > form > *": { margin: ".5rem" },
});

// Header.tsx of [snippethub-web], at 210926

import { styled } from "@mui/material";
import { MainHeader } from "./home-page-comps/MainHeader";
import { SecondaryHeader } from "./home-page-comps/SecondaryHeader";

export function Header() {
  return (
    <HeaderWrap>
      <SecondaryHeader />
      <MainHeader />
    </HeaderWrap>
  );
}

/* ======== STYLED ======== */
const HeaderWrap = styled("header")(({ theme }) => ({
  position: "relative",
  top: "0",
  width: "100%",
  borderBottom: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.default,
}));

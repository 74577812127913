// Footer.tsx of [snippethub-web], at 210916

import {
  CardHeader,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  styled,
} from "@mui/material";
import { GitHub, BorderStyle } from "@mui/icons-material";
import { VDiv } from "UI/Styled";
import { useNotYet } from "dev/devHooksHOC";

export function Footer() {
  const notYet = useNotYet();
  return (
    <TotalFooterWrap>
      <FooterWrap>
        <SectionWrap style={{ flex: "0 1 auto" }}>
          <VDiv>
            <IconButton aria-label="SnippetHub" size="large" onClick={notYet}>
              <H1BorderStyle />
            </IconButton>
            <IconButton aria-label="GitHub" size="large" onClick={notYet}>
              <H1GitHub />
            </IconButton>
          </VDiv>
        </SectionWrap>
        <SectionWrap>
          <CardHeader title="Support"></CardHeader>
          <List>
            <ListItem disablePadding>
              <ListItemButton
                onClick={notYet}
                // component="a"
                // href="https://docs.snippethub.dev/"
              >
                <ListItemText primary="Help(unavailable)" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component="a" href="/community">
                <ListItemText primary="Community" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component="a" href="/support">
                <ListItemText primary="Contact" />
              </ListItemButton>
            </ListItem>
          </List>
        </SectionWrap>
        <SectionWrap>
          <CardHeader title="Company"></CardHeader>
          <List>
            <ListItem disablePadding>
              <ListItemButton component="a" href="/about">
                <ListItemText primary="About" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={notYet}
                // component="a"
                // href="https://blog.snippethub.dev/"
              >
                <ListItemText primary="Blog(unavailable)" />
              </ListItemButton>
            </ListItem>
          </List>
        </SectionWrap>
        <SectionWrap>
          <CardHeader title="Terms & Policies"></CardHeader>
          <List>
            <ListItem disablePadding>
              <ListItemButton component="a" href="/policies">
                <ListItemText primary="Policies" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component="a" href="/policies/terms">
                <ListItemText primary="Terms of Use" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component="a" href="/policies/conduct">
                <ListItemText primary="Code of Conduct" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component="a" href="/policies/privacy">
                <ListItemText primary="Privacy" />
              </ListItemButton>
            </ListItem>
          </List>
        </SectionWrap>
      </FooterWrap>
    </TotalFooterWrap>
  );
}

/* ======== STYLED ======== */
const FooterWrap = styled("div")({
  display: "flex",
  justifyContent: "space-around",
  borderBottomWidth: "10px",
  borderBottomStyle: "solid",
  borderImage: "var(--three-color-gradient) 1",
  padding: "0 1rem 2rem",
});
const SectionWrap = styled("div")({
  flex: "1 1 25%",
  margin: "2rem",
  boxShadow: "none",
});
const TotalFooterWrap = styled("footer")(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.background.default,
  borderTop: `1px solid ${theme.palette.divider}`,
}));
const H1BorderStyle = styled(BorderStyle)(({ theme }) => ({
  fontSize: theme.typography.h1.fontSize,
}));
const H1GitHub = styled(GitHub)(({ theme }) => ({
  fontSize: theme.typography.h1.fontSize,
}));

import { User } from "@firebase/auth";
import { AnyAction } from "redux";

export { USER_types, userReducer, setUser, setObserveUser, userMiddleware };
/* ======== CONST ======== */
enum USER_types {
  USER_SET = "USER_SET",
  USER_OBSERVE = "USER_OBSERVE",
}

interface UserInterface {
  user: User | null;
  isObserving: boolean;
}

const initialSnackbarState = {
  user: null,
  isObserving: false,
};

/* ======== ACTIONS ======== */
interface ActionSetObserveUser {
  type: USER_types.USER_OBSERVE;
  payload: boolean;
}
function setObserveUser(isObserving: boolean): ActionSetObserveUser {
  return {
    type: USER_types.USER_OBSERVE,
    payload: isObserving,
  };
}

interface ActionSetUser {
  type: USER_types.USER_SET;
  payload: User | null;
}
function setUser(user: User | null): ActionSetUser {
  return {
    type: USER_types.USER_SET,
    payload: user,
  };
}

/* ======== REDUCER ======== */
// TODO: fix redundant AnyAction
type UserAction = ActionSetObserveUser | ActionSetUser | AnyAction;

const userReducer = (
  state: UserInterface = initialSnackbarState,
  action: UserAction
): UserInterface => {
  switch (action.type) {
    case USER_types.USER_OBSERVE: {
      return { ...state, isObserving: true };
    }
    case USER_types.USER_SET: {
      return {
        ...state,
        user: action.payload,
      };
    }
    default:
      return state;
  }
};

/* ======== MIDDLEWARE ======== */
const userMiddleware = (store: any) => (next: any) => (action: UserAction) => {
  if (action.type in USER_types) {
    if (action.type !== USER_types.USER_OBSERVE) {
      const isConnected = store.getState().user.isObserving;
      if (!isConnected) {
        console.error("user is not observed in [user]");
        // just for security, no other purpose.
      }
    }
  }
  return next(action);
};

import { initializeApp } from "firebase/app";
import { getAnalytics, isSupported } from "firebase/analytics";
const app = initializeApp({
  apiKey: process.env.REACT_APP_FIRE_API_KEY,
  appId: process.env.REACT_APP_FIRE_APP_ID,
  authDomain: process.env.REACT_APP_FIRE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIRE_REALTIME_DB_URL,
  measurementId: process.env.REACT_APP_FIRE_MEASUREMENT_ID,
  messagingSenderId: process.env.REACT_APP_FIRE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIRE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIRE_STORAGE_BUCKET,
});

var analytics = (async () =>
  (await isSupported()) ? getAnalytics(app) : undefined)();
// {
//   async () => {
//     if (await isSupported()) {
//       analytics = getAnalytics(app);
//     }
//   };
// }

export { app }; // make sure that app runs
export { analytics }; // make sure that analytics runs

import React, { useEffect } from "react";
// import "./App.css";
import {
  createTheme,
  styled,
  ThemeProvider,
  StyledEngineProvider,
  GlobalStyles,
  responsiveFontSizes,
} from "@mui/material";

import { BrowserRouter } from "react-router-dom";
import { grey, orange, yellow } from "@mui/material/colors";
import { usePaletteDarkMode } from "utils/hooks/usePaletteDarkMode";
import { Router } from "pages/Router";
import { TopSnackbar } from "UI/TopSnackbar";
import { useFirebaseUser } from "cloud/services";
import { useDispatch } from "react-redux";
import { setObserveUser } from "redux/user";

function App() {
  /* ======== Firebase ======== */
  const dispatch = useDispatch();
  dispatch(setObserveUser(true));
  const cleanFirebaseUser = useFirebaseUser();
  useEffect(() => {
    return () => {
      cleanFirebaseUser();
    };
    /* this is a lifecycle */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /* ======== MUI ======== */
  const { isPaletteDarkMode } = usePaletteDarkMode();
  const theme = React.useMemo(
    () => responsiveFontSizes(genThemeByMode(isPaletteDarkMode)),
    // TODO:WAIT: Fluid font sizes
    [isPaletteDarkMode]
  );

  /* ======== REACT ======== */
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <GlobalStyles
          styles={{
            ":root": {
              "--primary-weak":
                theme.palette.primary[theme.palette.mode as "dark" | "light"],
              "--text-primary": theme.palette.text.primary,
            },
          }}
        />
        <AppWarp>
          <BrowserRouter>
            <TopSnackbar />
            <Router />
          </BrowserRouter>
        </AppWarp>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
function genThemeByMode(isDarkMode: boolean) {
  return createTheme({
    palette: {
      mode: isDarkMode ? "dark" : "light",
      ...(isDarkMode
        ? {
            primary: { main: yellow["A700"] },
            secondary: { main: orange["A700"] },
          }
        : {
            primary: { main: yellow["700"] },
            secondary: { main: orange["700"] },
            background: { default: grey["100"] },
          }),
    },
    components: {
      /* This is nor working.
      /* MuiTypography: {
        styleOverrides: {
          h1: { "&.MuiTypography-gutterBottom": { marginBottom: 6.25 } },
        },
      }, */
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            "&:-webkit-autofill": {
              WebkitBoxShadow: "0 0 0 100px var(--primary-weak) inset",
              WebkitTextFillColor: "var(--text-primary)",
              // "-webkit-box-shadow": "0 0 0 100px var(--primary-weak) inset",
              // "-webkit-text-fill-color": "var(--text-primary)",
            },
          },
        },
      },
    },
    typography: {
      button: {
        textTransform: "none",
      },
    },
  });
}

/* ======== STYLED ======== */
const AppWarp = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#080808" : "#fff",
  minHeight: "100vh",
  maxWidth: "100vw",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  color: theme.palette.text.primary,
}));

import { Google } from "@mui/icons-material";
import { Button, ButtonProps } from "@mui/material";

export function GoogleOAuthBtn(props: Exclude<ButtonProps, "onClick">) {
  return (
    <Button onClick={clickHandler} {...props}>
      <Google />
    </Button>
  );
}

function clickHandler(event: React.MouseEvent) {
  event.preventDefault();
  alert("tmp-ly unavailable."); // const ghOAuthUrl = getGhOAuthUrl();
  // window.location.href = ghOAuthUrl;
}

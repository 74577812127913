import {
  Box,
  ButtonGroup,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { OAuthManage } from "component/acc-comps/OAuthManage";
import { UserAvatar } from "component/acc-comps/UserAvatar";
import { useNotYet } from "dev/devHooksHOC";
import { useSelector } from "redux/store";
import { CompoundPageWrap } from "UI/CompoundPageWrap";
import { SingleBodyWithTitle } from "UI/SingleBodyWithTitle";
import {
  CardContentWithMarginIn,
  CardHeaderWithBorder,
  OLButton,
  SFTextField,
  ThinCard,
} from "UI/Styled";

// TODO: use real userEmail
const userEmail = "pablolionshi@gmail.com";

/* ======== Style ======== */

export function UserAccountSettingPage() {
  const notYet = useNotYet();
  const { user: currUser } = useSelector((state) => state.user);
  // TODO: really check this
  if (!currUser) return null;

  return (
    <CompoundPageWrap>
      <SingleBodyWithTitle
        title="Account Settings"
        style={{ maxWidth: "96rem", width: "75%" }}
      >
        <ThinCard>
          <CardHeaderWithBorder
            sx={{ alignItems: "center" }}
            title="Profile"
            action={
              <OLButton size="small" href={`/~${currUser.displayName}/`}>
                View →
              </OLButton>
            }
          ></CardHeaderWithBorder>
          <CardContentWithMarginIn>
            <Typography>Profile Picture</Typography>
            <Box sx={{ width: "8rem", height: "8rem", m: 2 }}>
              <UserAvatar />
            </Box>
            <OLButton fullWidth onClick={notYet}>
              Change Avatar
            </OLButton>
            <Divider sx={{ mt: 2, mb: 1 }} />
            <Typography>Full Name</Typography>
            <SFTextField size="small" id="Full Name" />
            <Typography>GitHub Username</Typography>
            <SFTextField id="GitHub Username" />
            <Typography>Twitter Username</Typography>
            <SFTextField id="Twitter Username" />
            <Typography>Facebook Username</Typography>
            <SFTextField id="Facebook Username" />
            <Typography>Steam Link</Typography>
            <SFTextField id="Steam Link" />
            <Typography>Description</Typography>
            <TextField fullWidth multiline id="Description" />
            <ButtonGroup fullWidth size="large">
              <OLButton onClick={notYet}>Save</OLButton>
              <OLButton onClick={notYet}>Discard Changes</OLButton>
            </ButtonGroup>
          </CardContentWithMarginIn>
        </ThinCard>
        <ThinCard>
          <CardHeaderWithBorder title="Email & Password"></CardHeaderWithBorder>
          <CardContentWithMarginIn>
            <SFTextField id="Email" value={userEmail} disabled />
            <Typography>
              This email will be added to the metadata of snippets and bundles
              that you publish
            </Typography>
            <ButtonGroup fullWidth size="large">
              <OLButton onClick={notYet}>Change email</OLButton>
              <OLButton onClick={notYet}>Change password</OLButton>
            </ButtonGroup>
          </CardContentWithMarginIn>
        </ThinCard>
        <ThinCard>
          <CardHeaderWithBorder title="Two Factor Authentication"></CardHeaderWithBorder>
          <CardContentWithMarginIn>
            <OLButton onClick={notYet} fullWidth>
              Enable 2FA
            </OLButton>
          </CardContentWithMarginIn>
        </ThinCard>
        <ThinCard>
          <CardHeaderWithBorder title="Manage OAuth"></CardHeaderWithBorder>
          <CardContentWithMarginIn>
            <OAuthManage />
            <OLButton
              fullWidth
              href={`/settings/${currUser.displayName}/OAuth`}
            >
              Add OAuth
            </OLButton>
          </CardContentWithMarginIn>
        </ThinCard>
        <ThinCard>
          <CardHeaderWithBorder title="Delete Account"></CardHeaderWithBorder>
          <CardContentWithMarginIn>
            <Typography sx={{ mb: "1rem" }}>
              Once you delete your account, you will lose access to your
              packages. Please be certain.
            </Typography>
            <OLButton onClick={notYet} fullWidth color="error">
              Delete your account
            </OLButton>
          </CardContentWithMarginIn>
        </ThinCard>
        <div
          style={{ display: "table", width: "100%", height: "2rem" }}
          data-role="bottom-margin-holder"
        />
      </SingleBodyWithTitle>
    </CompoundPageWrap>
  );
}

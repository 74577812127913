import { FireAuthError, FireAuthErrorCode } from "../fire-constants";
import { readPath } from "./basic/readPath";

export async function getEmailByUsername(username: string) {
  try {
    const uid = await readPath("username2id/" + username);
    if (!uid) {
      throw new FireAuthError("X_INVALID_USERNAME");
    }
    return await readPath("users/" + uid + "/metadata/email");
  } catch (err: any) {
    if (err.code === FireAuthErrorCode.X_INVALID_USERNAME) {
      throw new FireAuthError("X_INVALID_USERNAME");
    }
    throw new Error("UNKNOWN ERROR:" + JSON.stringify(err));
  }
}

import { KeyboardArrowRight } from "@mui/icons-material";
import { Link, List, ListItem, Typography } from "@mui/material";
import { EmailBox } from "component/EmailBox";
import { useState } from "react";
import { CompoundPageWrap } from "UI/CompoundPageWrap";
import { SingleBodyWithTitle } from "UI/SingleBodyWithTitle";

export function AboutPage() {
  const [isShowEmailBox, setIsShowEmailBox] = useState<boolean>(false);
  function showMail() {
    setIsShowEmailBox(true);
  }
  return (
    <CompoundPageWrap>
      <SingleBodyWithTitle title="About SnippetHub">
        <Typography variant="body1">
          SnippetHub is a not company, at least not founded yet in 2021.
          SnippetHub has two main services right now.
        </Typography>
        <Typography variant="body1" style={{ margin: "1rem 0 .5rem" }}>
          The SnippetHub Visual Studio Code&copy; Extension{" "}
          <Link href="https://marketplace.visualstudio.com/items?itemName=PabloLION.snippethub">
            Snippet Hub
          </Link>{" "}
          helps:
        </Typography>
        <List>
          <ListItem>
            <KeyboardArrowRight /> Create snippet from selected text
          </ListItem>
          <ListItem>
            <KeyboardArrowRight /> Syntax highlighting for{" "}
            <code>.code-snippets</code> file
          </ListItem>
          <ListItem>
            {" "}
            <KeyboardArrowRight /> Search snippet with description.
          </ListItem>
        </List>
        <Typography variant="body1" style={{ margin: "1rem 0 .5rem" }}>
          The{" "}
          <Link href="https://www.snippethub.dev/support">SnippetHub.dev</Link>{" "}
          helps:{" "}
        </Typography>
        <List>
          <ListItem>
            <KeyboardArrowRight /> Manage user account, like OAuth link with
            GitHub/Google account.
          </ListItem>
          <ListItem>
            <KeyboardArrowRight /> Manage user-uploaded extensions.
          </ListItem>
        </List>
        <Typography variant="body1" style={{ margin: "1rem 0 .5rem" }}>
          If you want to support us, please{" "}
          <Link onClick={showMail} sx={{ cursor: "pointer" }}>
            contact admin
          </Link>
        </Typography>
        <br />
        <EmailBox {...{ isShowEmailBox }} />
      </SingleBodyWithTitle>
    </CompoundPageWrap>
  );
}

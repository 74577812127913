// SignUpPage.tsx of [snippethub-web], at 210926

import { Button, Divider, InputLabel, Typography } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { SimplePageWrap } from "UI/SimplePageWrap";
import { FormInfo } from "UI/types";
import { ValidatingField } from "UI/ValidatingField";
import { resetPasswordWithLink } from "cloud/firebase/auth/resetPasswordWithLink";
import { FireAuthErrorCode } from "cloud/firebase/fire-constants";
import { showTopSnackbar } from "redux/snackbar";
import { useDispatch } from "react-redux";
import { useNotYet } from "dev/devHooksHOC";

export function RecoverPasswordPage() {
  const notYet = useNotYet();
  const dispatch = useDispatch();
  const showTopSnackbarError = useCallback(
    (message: string) => {
      return dispatch(showTopSnackbar(message, "error"));
    },
    [dispatch]
  );
  const [usernameOrEmail, setUsernameOrEmail] = useState<FormInfo>({
    value: "",
    isValid: true,
  });
  const usernameOrEmailRef = useRef<HTMLInputElement>(null);
  const [sendEmailFinish, setSendEmailFinish] = useState<boolean>(false);
  const localFormCheck = useCallback(
    (usernameOrEmail): boolean => {
      const checkList: [FormInfo, React.RefObject<HTMLInputElement>, string][] =
        [[usernameOrEmail, usernameOrEmailRef, "Username or Email"]];
      for (const [info, ref, name] of checkList) {
        if (info.value === "") {
          ref.current?.focus();
          showTopSnackbarError(`Please fill out the field: ${name}`);
          return false;
        }
      }
      return true;
    },
    [showTopSnackbarError]
  );
  const serverFormCheck = useCallback(
    async (usernameOrEmail): Promise<boolean> => {
      if (!usernameOrEmail.value) {
        return false;
      }
      try {
        resetPasswordWithLink({
          usernameOrEmail: usernameOrEmail.value,
        });
        return true;
      } catch (err: any) {
        switch (err.code) {
          case FireAuthErrorCode.EMAIL_EXISTS:
            usernameOrEmailRef.current?.focus();
            showTopSnackbarError(
              `The email ${usernameOrEmail.value} email has already been registered.`
            );
            return false;
          default:
            console.error("UNKNOWN ERROR in [Recover Password]", err);
            return false;
        }
      }
    },
    [showTopSnackbarError]
  );
  const handleFormSubmit = useCallback(
    async (event, usernameOrEmail) => {
      notYet();
      event.preventDefault();
      if (!localFormCheck(usernameOrEmail)) {
        return false;
      }
      if (!(await serverFormCheck(usernameOrEmail))) {
        return false;
      }
      setSendEmailFinish(true);
    },
    [localFormCheck, serverFormCheck, notYet]
  );

  return (
    <SimplePageWrap>
      <Typography variant="h5">Recover Password</Typography>
      <Divider style={{ margin: "20px 0" }} variant="fullWidth" />
      {sendEmailFinish ? (
        <Typography>
          An email has been sent. Please click the link when you get it.
        </Typography>
      ) : (
        <form
          onSubmit={(event) => {
            handleFormSubmit(event, usernameOrEmail);
          }}
        >
          <Typography>Don't worry, happens to the best of us.</Typography>
          <InputLabel>Your email or SnippetHub username</InputLabel>
          <ValidatingField
            infoStateHook={[usernameOrEmail, setUsernameOrEmail]}
            lengthControl={[3, Infinity]}
            validators={[
              [
                "Name may not contain non-url-safe chars",
                (s) => /^[.a-zA-Z0-9_-]*$/.test(s),
              ],
              [
                'Name may not start with "."',
                (s: string) => !s.startsWith("."),
              ],
            ]}
            autocomplete="username"
            inputRef={usernameOrEmailRef}
            variant="outlined"
            size="small"
          />
          <Button variant="outlined" type="submit">
            email me a recovery link
          </Button>
        </form>
      )}
    </SimplePageWrap>
  );
}

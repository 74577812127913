// HomePage.tsx of [snippethub-web], at 210917

import { Header } from "../component/Header";
import { Footer } from "../component/Footer";
import { styled } from "@mui/system";

export function CompoundPageWrap(props: { children: React.ReactNode }) {
  /* ======== MUI ======== */

  return (
    <>
      <Header />
      <MainWrap>{props.children}</MainWrap>
      <Footer />
    </>
  );
}

/* ======== STYLED ======== */
const MainWrap = styled("main")({
  flexGrow: 1,
  width: "100%",
});

// Logo.tsx of [snippethub-web], at 210926

import { styled, useTheme } from "@mui/material";
import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import { Link } from "react-router-dom";
import { Variant } from "@mui/material/styles/createTypography";

interface LogoProps {
  style?: React.CSSProperties;
  variant?: Variant;
}

export function Logo(
  { style, variant = "h1", ...props }: LogoProps = { variant: "h1" }
): JSX.Element {
  const theme = useTheme();
  const variantFontSize = {
    fontSize: theme.typography[variant].fontSize,
  };

  return (
    <StyledLink to="/">
      <div style={{ ...variantFontSize, ...style }}>
        <DeveloperModeIcon style={variantFontSize} />
        SnippetHub
      </div>
    </StyledLink>
  );
}

/* ======== STYLED ======== */
const StyledLink = styled(Link)({
  color: "inherit",
  textDecoration: "none",
  "& :visited": {
    color: "inherit",
  },
});

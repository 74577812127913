import { Email } from "@mui/icons-material";
import { Alert, Box, Card, InputLabel, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { CompoundPageWrap } from "UI/CompoundPageWrap";
import { SingleBodyWithTitle } from "UI/SingleBodyWithTitle";
import {
  CardContentWithMarginIn,
  CardHeaderWithBorder,
  OLButton,
  ThinCard,
} from "UI/Styled";
export function BillingInformationPage() {
  const { username } = useParams<{ username: string }>();

  return (
    <CompoundPageWrap>
      <SingleBodyWithTitle
        title="Billing Information"
        style={{ maxWidth: "96rem", width: "75%" }}
      >
        <Box display="flex">
          <ThinCard>
            <CardHeaderWithBorder title='"Free" Plan'></CardHeaderWithBorder>
            <CardContentWithMarginIn>
              <Alert severity="success" variant="filled" icon={false}>
                <span
                  style={{
                    paddingRight: "0.25em",
                    borderRight: "1px solid lightgreen",
                    marginRight: "0.25em",
                  }}
                >
                  ✓
                </span>{" "}
                Unlimited Public Snippets & Bundles
              </Alert>
            </CardContentWithMarginIn>
          </ThinCard>
          <ThinCard>
            <CardHeaderWithBorder title="Monthly Bill"></CardHeaderWithBorder>
            <CardContentWithMarginIn>
              <InputLabel>Amount:</InputLabel>
              <Typography>$0</Typography>
              <InputLabel>No monthly bill</InputLabel>
            </CardContentWithMarginIn>
          </ThinCard>
        </Box>
        <Box display="flex">
          <ThinCard>
            <CardHeaderWithBorder title="Change Plan"></CardHeaderWithBorder>
            <CardContentWithMarginIn>
              Upgrading will enable:
              <Card variant="outlined" sx={{ my: "1rem" }}>
                <Typography sx={{ lineHeight: "2em", p: ".75em" }}>
                  ✓ Publish Private Snippets & Bundles
                  <br />✓ Install Private Snippets & Bundles
                </Typography>
              </Card>
              <OLButton color="success" fullWidth>
                Upgrade Plan ($1/User)
              </OLButton>
            </CardContentWithMarginIn>
          </ThinCard>
          <ThinCard>
            <CardHeaderWithBorder title="Get Support"></CardHeaderWithBorder>
            <CardContentWithMarginIn>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  my: "1rem",
                }}
              >
                <img
                  width="100px"
                  src="https://avatars.dicebear.com/api/avataaars/pablion.svg"
                  alt="customer-support-1"
                />
                <img
                  width="100px"
                  src="https://avatars.dicebear.com/api/avataaars/snippethub.svg"
                  alt="customer-support-2"
                />
                <img
                  width="100px"
                  src="https://avatars.dicebear.com/api/avataaars/jufengzhang.svg"
                  alt="customer-support-3"
                />
              </Box>
              <OLButton
                startIcon={<Email />}
                color="success"
                fullWidth
                href="/support"
              >
                Send us a message
              </OLButton>
            </CardContentWithMarginIn>
          </ThinCard>
        </Box>
        <ThinCard>
          <CardHeaderWithBorder title="Delete Organization"></CardHeaderWithBorder>
          <CardContentWithMarginIn>
            <Typography sx={{ mb: 2 }}>
              Delete this organization and any packages owned by it.
            </Typography>
            <OLButton fullWidth color={"error"}>
              Delete {username}
            </OLButton>
          </CardContentWithMarginIn>
        </ThinCard>
        <div
          style={{ display: "table", width: "100%", height: "2rem" }}
          data-role="bottom-margin-holder"
        />
      </SingleBodyWithTitle>
    </CompoundPageWrap>
  );
}

import { BoxProps, Box } from "@mui/material";

interface UserOrganizationsProps extends BoxProps {
  username: string;
}
export function UserOrganizations(props: UserOrganizationsProps) {
  return (
    <Box>
      Organizations of user <code>'{props.username}'</code> should be shown
      here, but this part is not finished yet.
    </Box>
  );
}

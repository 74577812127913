// UserIconDropdown.tsx of [snippethub-web], at 210917

import {
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
import {
  AddLink,
  BorderStyle,
  CreditCard,
  ArrowDropDown,
  GroupAdd,
  Logout,
  Person,
  PersonOutline,
  Settings,
  VpnKey,
  AllInbox,
} from "@mui/icons-material";
import { MouseEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { logOut } from "cloud/services";
import { GhOAuthBtn } from "component/oauth-comps/GhOAuthBtn";
import { useSelector } from "redux/store";
import { UserAvatar } from "component/acc-comps/UserAvatar";

interface LogoProps {
  style?: React.CSSProperties;
  withChevron?: boolean;
}

export function UserIconDropdown({ style, withChevron }: LogoProps) {
  const theme = useTheme();
  const { user: currUser } = useSelector((state) => state.user);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  /* ======== COMPONENT DID MOUNT ======== */

  useEffect(() => {
    return () => {};
    /* this is a lifecycle */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* ======== CHECK VARS ======== */

  if (!currUser) {
    return <>"NO CURRENT USER!"</>;
  }

  async function handleUserIconClick(event: MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    setAnchorEl(null);
  }
  const h5FontSize = {
    fontSize: theme.typography.h5.fontSize,
  };

  return (
    <div style={style}>
      <Button
        aria-label="account"
        aria-controls="account-menu"
        aria-haspopup="true"
        aria-expanded={Boolean(anchorEl) ? "true" : undefined}
        onClick={handleUserIconClick}
        style={{ paddingLeft: ".75rem" }}
      >
        <UserAvatar />
        {withChevron && <ArrowDropDown style={h5FontSize} />}
      </Button>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={handleClose}
        elevation={0}
        // sx={{ "& > div": { width: "180px" } }}
      >
        <MenuItem
          disabled
          style={{ fontWeight: "bold", color: "revert", opacity: "1" }}
        >
          <ListItemIcon>
            <PersonOutline style={h5FontSize} />
          </ListItemIcon>
          {currUser.displayName ?? "default_username"}
        </MenuItem>
        <Divider />
        <MenuItem
          component={Link}
          to={`/settings/${currUser.displayName}/OAuth`}
        >
          <ListItemIcon>
            <AddLink style={h5FontSize} />
          </ListItemIcon>
          <ListItemText primary="OAuth" />
          <GhOAuthBtn
            sx={{
              color: theme.palette.primary.main,
              p: 0,
              mr: 0,
            }}
          />
        </MenuItem>
        <MenuItem component={Link} to={`/~${currUser.displayName}`}>
          <ListItemIcon>
            <Person style={h5FontSize} />
          </ListItemIcon>
          <ListItemText primary="Profile" />
        </MenuItem>
        <MenuItem
          component={Link}
          to={`/settings/${currUser.displayName}/snippets`}
        >
          <ListItemIcon>
            <BorderStyle style={h5FontSize} />
          </ListItemIcon>
          <ListItemText primary="Snippets" />
        </MenuItem>
        <MenuItem
          component={Link}
          to={`/settings/${currUser.displayName}/bundles`}
        >
          <ListItemIcon>
            <AllInbox style={h5FontSize} />
          </ListItemIcon>
          <ListItemText primary="Bundles" />
        </MenuItem>
        <MenuItem
          component={Link}
          to={`/settings/${currUser.displayName}/profile`}
        >
          <ListItemIcon>
            <Settings style={h5FontSize} />
          </ListItemIcon>
          <ListItemText primary="Account" />
        </MenuItem>
        <MenuItem
          component={Link}
          to={`/settings/${currUser.displayName}/billing`}
        >
          <ListItemIcon>
            <CreditCard style={h5FontSize} />
          </ListItemIcon>
          <ListItemText primary="Billing Info" />
        </MenuItem>
        <MenuItem
          component={Link}
          to={`/settings/${currUser.displayName}/tokens`}
        >
          <ListItemIcon>
            <VpnKey style={h5FontSize} />
          </ListItemIcon>
          <ListItemText primary="Access Tokens" />
        </MenuItem>
        <Divider />
        {/* // TODO: add org should be also dynamic */}
        <MenuItem component={Link} to={`/org/create`}>
          <ListItemIcon>
            <GroupAdd style={h5FontSize} />
          </ListItemIcon>
          <ListItemText primary="Add Organization" />
        </MenuItem>
        <Divider />
        <MenuItem onClick={logOut}>
          <ListItemIcon>
            <Logout style={h5FontSize} />
          </ListItemIcon>
          <ListItemText primary="Log Out" />
        </MenuItem>
      </Menu>
    </div>
  );
}

// deepMerge.ts of [snippethub-web], at 211021

// Credit to https://stackoverflow.com/questions/27936772/how-to-deep-merge-instead-of-shallow-merge

/**
 * Simple object check
 * @param item
 * #returns {boolean}
 */
export function isObject(item: Object) {
  return item && typeof item === "object" && !Array.isArray(item);
}

type anObj = {
  [key: string]: any;
};

/**
 * Deep merge two objects
 * @param target
 * @param ...sources
 */
const overWritingWarn = (target: anObj, key: string) => {
  console.warn(
    "key",
    key,
    "in target",
    target,
    "is not an object. Merged with overwriting."
  );
};

export function deepMerge(target: anObj, ...sources: anObj[]): anObj {
  if (!sources.length) return target;
  const source = sources.shift() as anObj;

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, { [key]: {} });
        }
        if (!isObject(target[key])) {
          overWritingWarn(target, key);
          Object.assign(target, { [key]: {} });
        }
        deepMerge(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }
  return deepMerge(target, ...sources);
}

import { Typography } from "@mui/material";
import { OAuthManage } from "component/acc-comps/OAuthManage";
import { useSelector } from "redux/store";
import { CompoundPageWrap } from "UI/CompoundPageWrap";
import { SingleBodyWithTitle } from "UI/SingleBodyWithTitle";

export function UserOAuthPage() {
  const { user: currUser } = useSelector((state) => state.user);

  return (
    <CompoundPageWrap>
      <SingleBodyWithTitle title="OAuth Management">
        <Typography marginBottom="1rem">
          OAuth status of user <code>{currUser?.displayName}</code>
        </Typography>
        <OAuthManage showAll />
      </SingleBodyWithTitle>
    </CompoundPageWrap>
  );
}

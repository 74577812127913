import { logOut } from "cloud/services";
import {
  GithubAuthProvider,
  linkWithCredential,
  OAuthCredential,
  OAuthProvider,
  signInWithCredential,
  signInWithPopup,
} from "firebase/auth";
import { HotFixedAuthError } from "utils/types";
import { FireAuthErrorCode } from "../fire-constants";
import { auth, ghaProvider } from "./initFireAuth";

//  function startPopupGhOAuth() {
export async function startPopupGhOAuth(): Promise<any> {
  //TODO: fix promise type
  try {
    /* This can actually be sign-up */
    const ghAccessToken = await signInWithPopup(auth, ghaProvider);
    const credential = GithubAuthProvider.credentialFromResult(
      ghAccessToken
    ) as OAuthCredential;
    // TODO: redirect to add username
    // TODO: add username to database
    window.location.href = "http://localhost:3000/?SignUpGhOAuthSucceed";

    return { ghAccessToken, credential };
  } catch (err: any) {
    if (err.code === FireAuthErrorCode.NEED_CONFIRMATION) {
      try {
        linkExistedUser(err);
      } catch (err: any) {}
    } else {
      console.error("Unexpected Error in [startPopupGhOAuth]");
      throw err;
    }
  }
  // signInWithRedirect(auth, ghaProvider); // this needs pass things through session storage etc
}

// TODO: username existed error
export function linkExistedUser(accExistsErr: HotFixedAuthError) {
  // get errCredential
  const errOAuthCredential =
    GithubAuthProvider.credentialFromError(accExistsErr);
  if (!errOAuthCredential) {
    const noCredErr =
      "NO ERROR CREDENTIAL" + FireAuthErrorCode.NEED_CONFIRMATION;
    console.error(noCredErr);
    throw Error(noCredErr);
  }
  const data = accExistsErr.customData;
  if (!data.email) {
    throw Error("extraordinary internal error: GH acc must have an email.");
  }
  // get current user
  const currUser = auth.currentUser;
  if (!currUser) {
    alert(
      "Same email has be registered. Please login then link this account with GitHub"
    );
    // TODO : use MUI dialogue
    // TODO : login with email link
    // TODO : use can change email (&merge acc?)
    // TODO : use API here (&merge acc?)
    // const username = getUsernameByUid(getUidByEmail(data.email!));
    window.location.href = `/login?&then=linkGitHubAccount`;
    // const username = getUidByEmail(data.email!);
    // window.location.href = `/login?&username=${username}&then=linkGitHubAccount`;
    return;
  }
  if (currUser) {
    if (currUser.email === data.email) {
      linkWithCredential(currUser, errOAuthCredential).then((linkResult) => {
        // Sign in with the newly linked credential
        const linkCredential = OAuthProvider.credentialFromResult(linkResult);
        if (!linkCredential) {
          alert("Error(no cred);");
          throw Error("no cred");
        }
        return signInWithCredential(auth, linkCredential);
      });
    } else {
      const answerToSignOut = window.confirm(
        "current user email not github email,maybe you want to _LOG OUT_[OK] and create a new account?"
      );
      // TODO: Use MUI
      if (answerToSignOut) {
        logOut();
        redirectToSignUpWithGitHubInfo({
          username: (data._tokenResponse as any).screenName,
          email: data.email,
        });
        // TODO: improve redirect
      } else {
        alert("linking github failed.");
        // do nothing
      }
    }
  }
}

function redirectToSignUpWithGitHubInfo({
  username,
  email,
}: {
  username: string;
  email: string;
}) {
  window.location.href = `/signUp?username=${username}&email=${email}`;
}

import { Description } from "@mui/icons-material";
import { Button, Divider, Typography } from "@mui/material";
import { CompoundPageWrap } from "UI/CompoundPageWrap";
import { SingleBodyWithTitle } from "UI/SingleBodyWithTitle";

export function UserAccessTokenPage() {
  return (
    <CompoundPageWrap>
      <SingleBodyWithTitle
        title="Access Tokens"
        style={{ maxWidth: "96rem", width: "75%" }}
      >
        Currently we have no CLI, so no token is needed (I think).
        <Divider />
        <Typography>Rows 1 to 2 of 2</Typography>
        <Button>
          <Description /> Read the Documentation
        </Button>
      </SingleBodyWithTitle>
    </CompoundPageWrap>
  );
}

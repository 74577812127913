import { Email, Send } from "@mui/icons-material";
import { Box, Button } from "@mui/material";

function copyEmail() {
  navigator.clipboard.writeText("pablolionshi@gmail.com");
}
export function EmailBox(props: { isShowEmailBox: boolean }) {
  return (
    <>
      {props.isShowEmailBox && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <Button
            startIcon={<Send />}
            variant="outlined"
            href="mailto:pablolionshi@gmail.com"
          >
            Email admin
          </Button>
          <Button startIcon={<Email />} variant="outlined" onClick={copyEmail}>
            Copy Email Address
          </Button>
          <code>pablolionshi@gmail.com</code>
        </Box>
      )}
    </>
  );
}

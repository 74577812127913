import { getAuth, onAuthStateChanged } from "firebase/auth";
import { app } from "./firebase/initFire";
import { useDispatch } from "react-redux";
import { setObserveUser, setUser } from "redux/user";
export { app }; /* first export, make sure to run first */
export { signUp } from "./firebase/auth/signUp";
export { login } from "./firebase/auth/login";
export const fireAuth = getAuth(app);

export async function logOut() {
  return await fireAuth.signOut();
}

// React Hook
export function useFirebaseUser() {
  const dispatch = useDispatch();
  const cleanCurrUser = onAuthStateChanged(
    fireAuth,
    (user) => {
      if (user) {
        dispatch(setUser(fireAuth.currentUser));
      } else {
        dispatch(setUser(null));
      }
    },
    (err) => {
      console.error(`ERROR in [observeCurrUser]`);
      console.error(err);
    },
    () => {
      dispatch(setObserveUser(false));
    }
  );
  return cleanCurrUser;
}

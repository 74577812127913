// UserProfilePage.tsx of [snippethub-web], at 211021

import { Button, Chip, Divider, Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { UserAvatar } from "component/acc-comps/UserAvatar";
import { UserSnippets } from "component/acc-comps/UserSnippets";
import { UserOrganizations } from "component/acc-comps/UserOrganizations";
import { UserBundles } from "component/acc-comps/UserBundles";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { CompoundPageWrap } from "UI/CompoundPageWrap";
import { useNotYet } from "dev/devHooksHOC";

export function UserProfilePage() {
  const notYet = useNotYet();
  // TODO:  isOwner   isValidUser
  const isOwner = true;
  const isValidUser = true;
  const { username } = useParams<{ username: string }>();
  const [tabLabel, setTabLabel] = useState<string>("snippet");

  const tabContentData = useMemo(() => {
    /* this part for TS */
    let _tabLabel: string;
    if (["snippet", "bundle", "organization"].includes(tabLabel)) {
      _tabLabel = tabLabel;
    } else {
      console.error("wrong tab label in [UserProfilePage]");
      _tabLabel = "snippet bundle";
    }
    /* real content */
    if (_tabLabel === "snippet") {
      return {
        label: tabLabel,
        title: "Snippet",
        number: 1,
      };
    } else if (_tabLabel === "bundle") {
      return { label: tabLabel, title: "Bundle", number: 0 };
    } else {
      return { label: tabLabel, title: "Organization", number: 0 };
    }
  }, [tabLabel]);

  const handleTabChange = (
    event: React.SyntheticEvent,
    newTabLabel: string
  ) => {
    setTabLabel(newTabLabel);
  };

  return (
    <CompoundPageWrap>
      {isValidUser && (
        <Box
          sx={{ m: "4rem auto", display: "flex", width: "100%", px: "1rem" }}
        >
          <Box sx={{ width: "10rem", mr: "6rem" }}>
            <UserAvatar />
            <br />
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              {username}
            </Typography>
            <Divider sx={{ mt: 1, mb: 2 }} />
            {isOwner && (
              <Button variant="outlined" fullWidth onClick={notYet}>
                Edit Profile
              </Button>
            )}
          </Box>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tabLabel}
                onChange={handleTabChange}
                aria-label="tab switch"
                variant="fullWidth"
              >
                {/* // TODO: dynamic (S) */}
                <Tab label="1 Snippet(s)" value="snippet" />
                <Tab label="0 Bundle(s)" value="bundle" />
                <Tab label="0 Organization(s)" value="organization" />
              </Tabs>
            </Box>
            <Box>
              <Box
                role="tab-header"
                sx={{ p: 3, display: "flex", alignItems: "center" }}
              >
                <Typography display="inline" sx={{ mr: 1 }}>
                  {tabContentData.title}
                </Typography>
                <Chip
                  size="small"
                  variant="outlined"
                  label={tabContentData.number}
                />
                <Box sx={{ flexGrow: 1 }} />
                {isOwner && (
                  <Button variant="outlined" onClick={notYet}>
                    + Add Private Packages
                  </Button>
                )}
              </Box>
              {/* // TODO: This way is lazy-loading these 3 comps? */}
              {tabContentData.label === "snippet" ? (
                <UserSnippets username={username} />
              ) : tabContentData.label === "bundle" ? (
                <UserBundles username={username} />
              ) : (
                /* tabContentData.label ==="organization"  */
                <UserOrganizations username={username} />
              )}
              Also the badge/chip number is not correct.
            </Box>
          </Box>
        </Box>
      )}
    </CompoundPageWrap>
  );
}

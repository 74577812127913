import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { HomePage } from "pages/HomePage";
import { SignUpPage } from "pages/SignUpPage";
import { LoginPage } from "pages/LoginPage";
import React, { useEffect } from "react";
import { RedirectPage } from "./RedirectPage";
import { IntroPage } from "./IntroPage";
import { WebThankList } from "./WebThankList";
import { AboutPage } from "./AboutPage";
import { SupportPage } from "./SupportPage";
import { useSelector } from "redux/store";
import { PolicyPage } from "./policy-pages/PolicyPage";
import { TermsAndLicense } from "./policy-pages/TermsAndLicense";
import { CodeOfConduct } from "./policy-pages/CodeOfConduct";
import { DisputesResolution } from "./policy-pages/DisputesResolution";
import { PrivacyPolicy } from "./policy-pages/PrivacyPolicy";
import { E404Page } from "./ErrorNotFondPage";
import { UserProfilePage } from "./account-pages/UserProfilePage";
import { UserSnippetPage } from "./account-pages/UserSnippetPage";
import { UserBundlePage } from "./account-pages/UserBundlePage";
import { UserAccountSettingPage } from "./account-pages/UserAccountSettingPage";
import { BillingInformationPage } from "./account-pages/BillingInformationPage";
import { UserAccessTokenPage } from "./account-pages/UserAccessTokenPage";
import { CreateOrgPage } from "./org-pages/CreateOrgPage";
import { CommunityPage } from "./account-pages/CommunityPage";
import { UserOAuthPage } from "./account-pages/UserOAuthPage";
import { RecoverPasswordPage } from "./account-pages/RecoverPasswordPage";

export function Router() {
  /* ======== URL_QUERY ======== */
  const location = useLocation();
  const { user: currUser } = useSelector((state) => state.user);

  function ScrollToTop() {
    const { pathname } = location;
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return null;
  }

  useEffect(() => {
    return () => {};
    /* this is a lifecycle */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /* ======== test ======== */
  // console.log("currentUrl : ", currentUrl);

  /* ======== test end ======== */

  return (
    <React.Fragment>
      <ScrollToTop />
      <Switch>
        {!currUser && <Route exact path="/" children={<IntroPage />} />}
        {currUser && <Redirect exact from="/login" to="/" />}
        {currUser && <Redirect exact from="/signUp" to="/" />}
        <Route path="/redirect" children={<RedirectPage />} />
        <Route exact path="/" children={<HomePage />} />
        <Route exact path="/login" children={<LoginPage />} />
        <Route exact path="/signUp" children={<SignUpPage />} />
        <Route exact path="/web-thank-list" children={<WebThankList />} />
        <Route exact path="/about" children={<AboutPage />} />
        <Route exact path="/community" children={<CommunityPage />} />
        <Route exact path="/recover" children={<RecoverPasswordPage />} />
        <Route exact path="/support" children={<SupportPage />} />
        <Route exact path="/policies" children={<PolicyPage />} />
        <Route exact path="/policies/terms" children={<TermsAndLicense />} />
        <Route exact path="/policies/conduct" children={<CodeOfConduct />} />
        <Route
          exact
          path="/policies/disputes"
          children={<DisputesResolution />}
        />
        <Route exact path="/policies/privacy" children={<PrivacyPolicy />} />
        <Route exact path="/~:username" children={<UserProfilePage />} />
        {/* //TODO: ALL BELOW, Check if authed for editing. */}
        <Route
          exact
          path="/settings/:username/snippets"
          children={<UserSnippetPage />}
        />
        <Route
          exact
          path="/settings/:username/bundles"
          children={<UserBundlePage />}
        />
        <Route
          exact
          path="/settings/:username/profile"
          children={<UserAccountSettingPage />}
        />
        <Route
          exact
          path="/settings/:username/billing"
          children={<BillingInformationPage />}
        />
        <Route
          exact
          path="/settings/:username/OAuth"
          children={<UserOAuthPage />}
        />
        <Route
          exact
          path="/settings/:username/tokens"
          children={<UserAccessTokenPage />}
        />
        <Route exact path="/org/create" children={<CreateOrgPage />} />
        <Route children={<E404Page />} />
      </Switch>
    </React.Fragment>
  );
}

import { GitHub } from "@mui/icons-material";
import { ButtonProps, Button } from "@mui/material";
import { startPopupGhOAuth } from "cloud/firebase/auth/ghOAuth";
import React from "react";

export function GhOAuthBtn(props: Exclude<ButtonProps, "onClick">) {
  return (
    <Button onClick={requireGhOAuth} {...props}>
      <GitHub />
    </Button>
  );
}

function requireGhOAuth(event: React.MouseEvent) {
  event.preventDefault();
  try {
    startPopupGhOAuth();
  } catch (err: any) {
    console.log(err);
  }
}

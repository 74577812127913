// HomePage.tsx of [snippethub-web], at 210917

import { MainPopular } from "../component/home-page-comps/MainPopular";
import { MainDiscover } from "../component/home-page-comps/MainDiscover";
import { MainStat } from "../component/home-page-comps/MainStat";
import { RecentSnippets } from "../component/home-page-comps/RecentSnippets";
import { styled } from "@mui/material";
import { CompoundPageWrap } from "UI/CompoundPageWrap";

export function HomePage() {
  /* ======== MUI ======== */

  return (
    <CompoundPageWrap>
      <BodyWrap>
        <CardsWrap>
          <MainPopular />
          <MainDiscover />
          <MainStat />
        </CardsWrap>
        <CardsWrap>
          <RecentSnippets />
        </CardsWrap>
      </BodyWrap>
    </CompoundPageWrap>
  );
}

/* ======== STYLED ======== */
const BodyWrap = styled("div")({
  flex: "1 1 auto",
  margin: "0 20px 40px",
});
const CardsWrap = styled("div")(({ theme }) => ({
  position: "relative",
  margin: "20px 0 0",
  display: "flex",
  maxWidth: "100%",
  padding: "10px",
  backgroundColor: theme.palette.background.default,
}));

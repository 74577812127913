import {
  Apple,
  BorderAll,
  CheckCircle,
  Facebook,
  GitHub,
  Google,
  Twitter,
} from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useNotYet } from "dev/devHooksHOC";
import { useCallback, useRef, useState } from "react";
import { deepMerge } from "utils/deepMerge";

const OAuthLib = {
  GitHub: { icon: <GitHub /> },
  Google: { icon: <Google /> },
  Microsoft: { icon: <BorderAll /> },
  Apple: { icon: <Apple /> },
  Facebook: { icon: <Facebook /> },
  Twitter: { icon: <Twitter /> },
};
interface OAuthManageProps {
  showAll?: boolean;
}
const initialHoverControl: {
  [key in keyof typeof OAuthLib]: boolean;
} = {
  GitHub: false,
  Google: false,
  Microsoft: false,
  Apple: false,
  Facebook: false,
  Twitter: false,
};

export function OAuthManage(
  { showAll = false }: OAuthManageProps = { showAll: false }
) {
  const notYet = useNotYet();
  // TODO: Use Real OAuth data===userOAuthStatus
  const userOAuthStatus = { GitHub: { linked: true } };
  const renderOAuth = deepMerge({}, OAuthLib, userOAuthStatus);
  const [hoverControl, setHoverControl] = useState<{
    [key in keyof typeof OAuthLib]: boolean;
    // TODO: type with [key: keyof typeof OAuthLib]: boolean;
  }>(initialHoverControl);
  const hoverTimeout = useRef<NodeJS.Timeout>();
  // var hoverTimeout: NodeJS.Timeout;
  // only render what is Hovered after a delay (for animation)

  const handleMouseEnter = useCallback((nameOAuth: keyof typeof OAuthLib) => {
    hoverTimeout.current = setTimeout(() => {
      setHoverControl({ ...initialHoverControl, [nameOAuth]: true });
    }, 100);
  }, []);
  const handleMouseLeave = useCallback((event) => {
    clearTimeout(hoverTimeout.current!);
    setHoverControl(initialHoverControl);
  }, []);

  return (
    <Box display="flex" justifyContent="space-evenly">
      {(Object.keys(renderOAuth) as Array<keyof typeof OAuthLib>).map(
        (nameOAuth) => {
          const curr = renderOAuth[nameOAuth];
          const currHovered = hoverControl[nameOAuth];
          return (
            (showAll || curr.linked) && (
              <Box
                key={nameOAuth}
                minWidth="6rem"
                maxWidth="9rem"
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignContent="center"
                justifyItems="flex-start"
                alignItems="center"
                onMouseEnter={() => handleMouseEnter(nameOAuth)}
                onMouseLeave={handleMouseLeave}
                sx={{
                  width: "6rem",
                  transition: "width 0.25s ease",
                  "&:hover": {
                    width: curr.linked ? "9rem" : "6rem",
                    transition: "width 0.5s ease-out",
                  },
                }}
              >
                <Box display="flex" alignItems="center">
                  {curr.icon}
                  {curr.linked && currHovered && (
                    <Typography display="inline">{nameOAuth}</Typography>
                  )}
                </Box>
                <Box display="flex" alignItems="center" marginY="1rem">
                  {curr.linked ? (
                    <>
                      <CheckCircle />
                      {currHovered && (
                        <Typography display="inline">Linked</Typography>
                      )}
                    </>
                  ) : (
                    <Typography display="inline">{nameOAuth}</Typography>
                  )}
                </Box>
                {curr.linked ? (
                  <Button
                    size="small"
                    variant="outlined"
                    color="error"
                    onClick={notYet}
                  >
                    Unlink
                  </Button>
                ) : (
                  <Button size="small" variant="outlined" onClick={notYet}>
                    Link Now
                  </Button>
                )}
              </Box>
            )
          );
        }
      )}
    </Box>
  );
}

import {
  Button,
  ButtonProps,
  Card,
  CardContent,
  CardHeader,
  CardProps,
  Divider,
  styled,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";

export const WideSectionWrap = styled("div")({
  maxWidth: "48rem",
  margin: "4rem auto",
  textAlign: "center",
});

export const WideDiv = styled("div")({
  flex: "1 1 25%",
  minHeight: "490px",
  margin: "2rem",
  boxShadow: "none",
});

export const VDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "max-content",
});

const StyledDivider = styled(Divider)({
  borderWidth: "1px 0",
  borderStyle: "solid",
  margin: "10px",
  height: 0,
});
interface WideDivHeaderProps {
  title: string;
  icon?: JSX.Element;
  divider?: string;
}

/**
 * returns a JSX includes an icon, text title and a divider.
 *
 * @param {{title}} string : text title
 * @param {{icon?}} JSX.Element : icon used
 * @param {{divider?}} string : css variable / string of color / linear-gradient
 */
export function WideDivHeader({
  title,
  icon,
  divider,
}: WideDivHeaderProps): JSX.Element {
  let borderImage = "";
  if (!divider?.includes("gradient")) {
    borderImage = `linear-gradient(0deg,${divider},${divider})1`;
  } else {
    borderImage = divider;
  }
  return (
    <div>
      <div style={{ margin: "20px", display: "flex", alignItems: "flex-end" }}>
        <div style={{ margin: "0 10px" }}>{icon}</div>
        <Typography variant="h5">{title}</Typography>
      </div>
      {divider && (
        <StyledDivider
          style={{
            borderImage: borderImage,
          }}
          variant="middle"
        />
      )}
    </div>
  );
}

export const CardHeaderWithBorder = styled(CardHeader)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export const CardContentWithMarginIn = styled(CardContent)({
  "& > *": { margin: "0.5rem 0" },
});

export function SFTextField({ sx, ...props }: TextFieldProps) {
  return <TextField size="small" fullWidth {...props} />;
}

export function ThinCard({ sx, ...props }: CardProps) {
  const newSx = {
    /* npm-width: "40%", */
    /* npm-m: "0 1rem 1rem 0", */
    width: "calc(50% - 1rem)",
    m: ".5rem",
    display: "block",
    float: "left",
  };
  return (
    <Card
      variant="outlined"
      sx={Object.assign({}, newSx, sx)}
      /* to suggest: sx={...newSx} */
      {...props}
    />
  );
}

export function OLButton(props: ButtonProps) {
  return <Button variant="outlined" size="large" {...props} />;
}

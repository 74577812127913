// RedirectPage.tsx of [snippethub-web], at 210928
/* Here I do the backend things. */
/* Temporarily Only for GH OAuth */

import { GitHub } from "@mui/icons-material";
import { Divider, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { SimplePageWrap } from "UI/SimplePageWrap";

const currentUrl = new URL(window.location.href);
const query = new URLSearchParams(currentUrl.search);
const host = currentUrl.host;

/* ======== PROD_ENV ======== */
if (host === "www.snippethub.dev") {
  if (query.has("GHOAuthSuccessCallback")) {
    alert("Authenticate by GitHub successfully.");
  }
}

/* ======== DEV_ENV ======== */
if (host === "www.snippethub.dev") {
  if (query.has("GHOAuthSuccessCallbackTest")) {
    query.delete("GHOAuthSuccessCallbackTest");
    query.append("GHOAuthSuccessCallbackTestFromWeb", "");
    alert("Authenticate by GitHub successfully. Redirecting to localhost");
    window.location.href = `http://localhost:3000/redirect/github/?${query.toString()}`;
  }
}
if (host === "localhost") {
  if (query.has("GHOAuthSuccessCallbackTestFromWeb")) {
    console.log(query.toString());
  }
}

export function GhOauthRedirectPage() {
  const { goal } = useParams<{ goal?: string }>();
  console.log("goal : ", goal);

  return (
    <SimplePageWrap>
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <GitHub style={{ fontSize: "150px" }} />
        <Divider />
        <Typography variant="h4">
          Congrats! You did GitHub OAuth successfully.
        </Typography>
        {goal ? (
          <div>
            goal: <code>{goal}</code>
          </div>
        ) : (
          ""
        )}
      </div>
    </SimplePageWrap>
  );
}

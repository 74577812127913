// SignUpPage.tsx of [snippethub-web], at 210926

import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  InputLabel,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { SimplePageWrap } from "UI/SimplePageWrap";
import { FormInfo } from "UI/types";
import { ValidatingField } from "UI/ValidatingField";
import { signUp } from "cloud/services";
import { FireAuthErrorCode } from "cloud/firebase/fire-constants";
import { showTopSnackbar } from "redux/snackbar";
import { useDispatch } from "react-redux";
import { useNotYet } from "dev/devHooksHOC";

export function SignUpPage() {
  const notYet = useNotYet();
  let history = useHistory();
  const dispatch = useDispatch();
  const showTopSnackbarError = useCallback(
    (message: string) => {
      return dispatch(showTopSnackbar(message, "error"));
    },
    [dispatch]
  );
  const showTopSnackbarSuccess = useCallback(
    (message: string) => {
      return dispatch(showTopSnackbar(message, "success"));
    },
    [dispatch]
  );
  const defaultFormInfo = {
    value: "",
    isValid: true,
  };
  const [username, setUsername] = useState<FormInfo>({ ...defaultFormInfo });
  const [email, setEmail] = useState<FormInfo>({ ...defaultFormInfo });
  const [password, setPassword] = useState<FormInfo>({ ...defaultFormInfo });
  const [isAgreed, setIsAgreed] = useState<boolean>(true); // repeated to improve useEffect
  const usernameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const userAgreementRef = useRef<HTMLInputElement>(null);
  const [isDisabledCreateBtn, setIsDisabledCreateBtn] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      !isAgreed ||
      ![username, email, password].map((fi) => fi.isValid).every(Boolean)
    ) {
      setIsDisabledCreateBtn(true);
    } else {
      setIsDisabledCreateBtn(false);
    }
  }, [username, email, password, isAgreed]);

  const localFormCheck = useCallback(
    (username, email, password): boolean => {
      const checkList: [FormInfo, React.RefObject<HTMLInputElement>, string][] =
        [
          [username, usernameRef, "username"],
          [email, emailRef, "email"],
          [password, passwordRef, "password"],
        ];
      for (const [info, ref, name] of checkList) {
        if (info.value === "") {
          ref.current?.focus();
          showTopSnackbarError(`Please fill out the field: ${name}`);
          return false;
        }
      }
      if (!userAgreementRef.current?.checked) {
        userAgreementRef.current?.focus();
        showTopSnackbarError(
          `Please agree End User License Agreement and Privacy Policy.`
        );
        return false;
      }
      return true;
    },
    [showTopSnackbarError]
  );
  const serverFormCheck = useCallback(
    async (username, email, password): Promise<boolean> => {
      if (!username.value || !email.value || !password.value) {
        return false;
      }
      try {
        await signUp({
          username: username.value,
          email: email.value,
          password: password.value,
        });
        // TODO: next line add a disappear?
        showTopSnackbarSuccess("Signed up successfully, logging in...");
        return true;
      } catch (err: any) {
        switch (err.code) {
          case FireAuthErrorCode.EMAIL_EXISTS:
            usernameRef.current?.focus();
            showTopSnackbarError(
              `The email ${username.value} email has already been registered.`
            );
            return false;
          case FireAuthErrorCode.X_USERNAME_EXISTS:
            usernameRef.current?.focus();
            showTopSnackbarError(
              `The username ${username.value} is already taken. Please try another`
            );
            return false;
          default:
            console.error("UNKNOWN ERROR in SignUp", err);
            return false;
        }
      }
    },
    [showTopSnackbarError, showTopSnackbarSuccess]
  );
  const handleFormSubmit = useCallback(
    async (event, username, email, password) => {
      event.preventDefault();
      if (!localFormCheck(username, email, password)) {
        return false;
      }
      if (!(await serverFormCheck(username, email, password))) {
        return false;
      }
      history.push("/");
    },
    [history, localFormCheck, serverFormCheck]
  );

  return (
    <SimplePageWrap>
      <form
        onSubmit={(event) => {
          handleFormSubmit(event, username, email, password);
        }}
      >
        <Typography variant="h5">Sign up</Typography>
        <Divider style={{ margin: "20px 0" }} variant="fullWidth" />
        <InputLabel>Username</InputLabel>
        <ValidatingField
          infoStateHook={[username, setUsername]}
          lengthControl={[3, Infinity]}
          validators={[
            [
              "Name may not contain non-url-safe chars",
              (s) => /^[.a-zA-Z0-9_-]*$/.test(s),
            ],
            ['Name may not start with "."', (s: string) => !s.startsWith(".")],
          ]}
          autocomplete="username"
          inputRef={usernameRef}
          variant="outlined"
          size="small"
        />
        <InputLabel>Email address</InputLabel>
        <ValidatingField
          infoStateHook={[email, setEmail]}
          autocomplete="email"
          inputRef={emailRef}
          variant="outlined"
          size="small"
          type="email"
        />
        <InputLabel>Password</InputLabel>
        <ValidatingField
          infoStateHook={[password, setPassword]}
          type="password"
          autocomplete="password"
          inputRef={passwordRef}
          lengthControl={[8, Infinity]}
          variant="outlined"
          size="small"
        />
        <span>
          <span style={{ fontWeight: 700 }}>Note:</span> Your email address will
          be added to the metadata of snippets that you publish, so it may be
          seen publicly.
        </span>
        <span>Your password should be at least 8 characters. </span>
        <Link onClick={notYet}>Learn more</Link>
        <Paper variant="outlined" style={{ padding: "10px" }}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(event, checked) => {
                  setIsAgreed(checked);
                }}
                inputRef={userAgreementRef}
              />
            }
            label={
              <div>
                Agree to the{" "}
                <Link
                  href="/policies/terms"
                  target="_blank"
                  title="SnippetHub, Inc. Terms and Licenses"
                >
                  End User License Agreement
                </Link>{" "}
                and the{" "}
                <Link
                  href="/policies/privacy"
                  target="_blank"
                  title="SnippetHub, Inc. Privacy Policy"
                >
                  Privacy Policy
                </Link>
                .
              </div>
            }
          />
        </Paper>
        <Button variant="outlined" type="submit" disabled={isDisabledCreateBtn}>
          Create an Account
        </Button>
      </form>
      <Link style={{ alignSelf: "center", fontWeight: "bold" }} href="/login">
        or, Login
      </Link>
    </SimplePageWrap>
  );
}

// usePaletteDarkMode.ts of [snippethub-web], at 211013
// hooks.ts of [snippethub-web], at 210923

/* usage:
 *   const { isPaletteDarkMode, paletteMode, togglePaletteDarkMode } = usePaletteDarkMode();
 */
import { useEffect, useState } from "react";
import { useLocalStorage } from "usehooks-ts";
import type { PaletteMode } from "utils/types";

const COLOR_SCHEME_QUERY = "(prefers-color-scheme: dark)";

interface UseDarkModeOutput {
  isPaletteDarkMode: boolean;
  paletteMode: PaletteMode;
  togglePaletteDarkMode: () => void;
}

function usePaletteDarkMode(defaultValue: boolean = true): UseDarkModeOutput {
  /* ======== HELPERS ======== */
  function getPrefersDarkMode(): boolean {
    // Prevents SSR issues
    if (typeof window !== "undefined") {
      if (window.hasOwnProperty("matchMedia")) {
        return window.matchMedia(COLOR_SCHEME_QUERY).matches;
      }
    }
    return !!defaultValue;
  }
  /* ======== REACT ======== */
  const [prefersDarkMode, setPrefersDarkMode] = useState<boolean>(
    getPrefersDarkMode()
  );
  const [paletteMode, setPaletteMode] = useLocalStorage<PaletteMode>(
    "paletteMode",
    "system"
  );
  const [isPaletteDarkMode, setIsPaletteDarkMode] = useState<boolean>(true);
  // TODO: cant test with next line.
  // const mQuery = useMediaQuery(COLOR_SCHEME_QUERY);

  // Update darkMode if os prefers changes
  useEffect(() => {
    const handler = () => setPrefersDarkMode(getPrefersDarkMode());
    const matchMedia = window.matchMedia(COLOR_SCHEME_QUERY);
    matchMedia.addEventListener("change", handler);
    return () => {
      matchMedia.removeEventListener("change", handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // for setIsPaletteDarkMode
    if (paletteMode === "dark") {
      setIsPaletteDarkMode(true);
    } else if (paletteMode === "system") {
      setIsPaletteDarkMode(prefersDarkMode);
    } else if (paletteMode === "light") {
      setIsPaletteDarkMode(false);
    } else {
      console.error("ERR reading palette mode");
    }
  }, [paletteMode, prefersDarkMode]);

  return {
    isPaletteDarkMode,
    paletteMode,
    togglePaletteDarkMode: () => {
      setPaletteMode((currMode: PaletteMode) =>
        currMode === "dark" ? "light" : currMode === "light" ? "system" : "dark"
      );
    },
  };
}

export { usePaletteDarkMode };

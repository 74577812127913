const sizeSuffixes = ["px", "rem", "em"]; /* rem>em */
export function rescale(size: number | string | undefined, scalar: number) {
  if (!size) {
    return size;
  }
  if (typeof size === "number") {
    return size * scalar;
  }
  if (typeof size === "string") {
    if (!isNaN(Number(size))) {
      return `${+size * scalar}`;
    }
    for (const suffix of sizeSuffixes) {
      if (size.endsWith(suffix)) {
        const numPart = size.slice(0, -suffix.length);
        const num = Number(numPart);
        if (isNaN(num)) {
          console.error(
            `[uiHelper]: string "${numPart}" cannot parse to number`
          );
          return size;
        }
        return `${num * scalar}${suffix}`;
      }
    }
  }
  console.error(`[uiHelper]: converting "${size}" failed.`);
  return size;
}
